<template>
  <v-container cols="12" class="level3">
    <!-- 問題 -->
    {{ question.question }}
    <span v-if="question.unit">({{ question.unit }})</span>
    <span v-if="question.required" class="red--text">*</span>
    <span v-if="question.question === '出生日期' && question.age !== null"
      >({{ question.age }}歲)</span
    >

    <v-col cols="12">
      <div v-if="question.type === 'textfield'">
        <v-text-field
          dense
          v-model="question.answer"
          :label="question.label"
          :type="
            ['體溫', '心跳', '呼吸', '血氧', '血糖'].includes(question.question) ? 'number' : 'text'
          "
          ref="textField"
        ></v-text-field>

        <!-- 選擇後顯示子問題 -->
        <!-- 當 text-field 有輸入文字 且存在 subquestions 時顯示 subquestions -->
        <v-row v-if="question.answer && question.subquestions">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            {{ subquestion.question }}
            <div class="subquestion-title">
              <v-text-field
                v-if="subquestion.type === 'textfield'"
                v-model="subquestion.answer"
                dense
              ></v-text-field>
              <div v-else-if="subquestion.type === 'checkbox'">
                <v-btn-toggle v-model="subquestion.answer" dense style="flex-wrap: wrap">
                  <v-btn
                    v-for="option in subquestion.options"
                    :key="option"
                    :value="option"
                    color="#ffb200"
                    class="font-weight-bold ma-1 text-subtitle-1"
                    active-class="selected-option"
                  >
                    {{ option }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="question.type === 'textarea'">
        <v-textarea v-model="question.answer" outlined dense></v-textarea>
      </div>
      <div v-else-if="question.type === 'checkbox'">
        <v-btn-toggle v-model="question.answer" dense style="flex-wrap: wrap">
          <v-btn
            v-for="option in question.options"
            :key="option"
            :value="option"
            color="#ffb200"
            class="font-weight-bold ma-1 text-subtitle-1"
            active-class="selected-option"
          >
            {{ option }}
          </v-btn>
          <v-text-field
            dense
            class="pa-2"
            v-if="question.answer && question.answer.includes('其他')"
            v-model="question.other"
            label="請描述"
          ></v-text-field>
        </v-btn-toggle>
        <v-row v-if="question.answer && question.subquestions">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            <span v-if="question.answer === '無'">{{ subquestion.question.split('or')[0] }}</span>
            <span v-else>{{ subquestion.question.split('or')[1] }}</span>
            <v-menu
              v-if="subquestion.type === 'date'"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              absolute
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="subquestion.answer"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="subquestion.answer"
                no-title
                scrollable
                dense
                show-adjacent-months
                absolute
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
            <v-menu
              v-else-if="subquestion.type === 'datetime'"
              v-model="activeMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="subquestion.answer"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="showDatePicker"
                v-model="selectedDate"
                no-title
                scrollable
                dense
                show-adjacent-months
                absolute
                @change="onDateSelected($event, subquestion)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
              <v-time-picker
                v-else
                v-model="selectedTime"
                scrollable
                format="24hr"
                dense
                @change="onTimeSelected($event, subquestion)"
              ></v-time-picker>
            </v-menu>
            <v-text-field
              v-else-if="subquestion.type === 'textfield'"
              v-model="subquestion.answer"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="question.type === 'switch'">
        <v-switch color="blue" v-model="question.answer"></v-switch>

        <!-- 選擇後顯示子問題 -->
        <!-- 當 switch 打開且存在 subquestions 時顯示 subquestions -->
        <v-row v-if="question.answer && question.subquestions">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            {{ subquestion.question }}
            <span v-if="subquestion.unit">({{ subquestion.unit }})</span>
            <span v-if="subquestion.label">({{ subquestion.label }})</span>
            <span v-if="subquestion.required" class="red--text">*</span>
            <div class="subquestion-title">
              <v-text-field
                v-if="subquestion.type === 'textfield'"
                v-model="subquestion.answer"
                dense
              ></v-text-field>
              <v-textarea
                v-else-if="subquestion.type === 'textarea'"
                v-model="subquestion.answer"
                dense
              ></v-textarea>
              <v-switch v-else-if="subquestion.type === 'switch'" v-model="subquestion.answer">
              </v-switch>
              <div v-else-if="subquestion.type === 'checkbox'">
                <v-btn-toggle v-model="subquestion.answer" dense style="flex-wrap: wrap">
                  <v-btn
                    v-for="option in subquestion.options"
                    :key="option"
                    :value="option"
                    color="#ffb200"
                    class="font-weight-bold ma-1 text-subtitle-1"
                    active-class="selected-option"
                  >
                    {{ option }}
                  </v-btn>
                  <v-text-field
                    dense
                    class="pa-2"
                    v-if="subquestion.answer == '其他' && subquestion.answer"
                    v-model="question.other"
                    label="請描述"
                  ></v-text-field>
                </v-btn-toggle>
              </div>

              <v-text-field
                dense
                class="pa-2"
                v-if="subquestion.question == '其他' && subquestion.answer"
                v-model="question.other"
                label="請描述"
              ></v-text-field>

              <v-btn-toggle
                dense
                style="flex-wrap: wrap"
                multiple
                v-model="subquestion.answer"
                v-else-if="subquestion.type === 'multichoice'"
              >
                <v-btn
                  v-for="(subquestionOption, subquestionOptionIndex) in subquestion.options"
                  :key="subquestionOptionIndex"
                  :value="subquestionOption"
                  color="#ffb200"
                  class="font-weight-bold ma-1 text-subtitle-1"
                  active-class="selected-option"
                >
                  {{ subquestionOption }}
                </v-btn>
                <v-text-field
                  v-if="Array.isArray(subquestion.answer) && subquestion.answer.includes('其他')"
                  v-model="subquestion.other"
                  label="請描述"
                ></v-text-field>
              </v-btn-toggle>
              <v-menu
                v-else-if="subquestion.type === 'date'"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="subquestion.answer"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="subquestion.answer"
                  no-title
                  scrollable
                  dense
                  show-adjacent-months
                ></v-date-picker>
              </v-menu>
              <div v-else-if="subquestion.type === 'multifield'">
                <v-row>
                  <v-col v-for="(detail, detailIndex) in subquestion.details" :key="detailIndex">
                    <div class="subquestion-title">
                      <v-text-field
                        v-if="detail.type === 'textfield'"
                        v-model="detail.answer"
                        :label="detail.question"
                        dense
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row v-else-if="question.type === 'multichoice'">
        <v-btn-toggle v-model="question.answer" dense style="flex-wrap: wrap" multiple>
          <template v-for="(option, index) in question.options">
            <div :key="option" style="display: flex; align-items: center; flex-wrap: wrap">
              <v-btn
                :value="option"
                color="#ffb200"
                class="font-weight-bold ma-1 text-subtitle-1"
                active-class="selected-option"
              >
                {{ option }}
              </v-btn>
              <v-text-field
                v-if="
                  ['疼痛，部位'].includes(option) &&
                  question.answer &&
                  question.answer.includes('疼痛，部位')
                "
                v-model="question.part"
                dense
                label="請描述部位"
                style="margin-left: 10px"
                hide-details="true"
              ></v-text-field>
              <v-text-field
                v-else-if="
                  ['癌症'].includes(option) && question.answer && question.answer.includes('癌症')
                "
                v-model="question.cancer"
                dense
                label="請描述"
                style="margin-left: 10px"
                hide-details="true"
              ></v-text-field>
              <v-text-field
                v-else-if="
                  ['其他'].includes(option) && question.answer && question.answer.includes('其他')
                "
                v-model="question.other"
                dense
                label="請描述"
                style="margin-left: 10px"
                hide-details="true"
              ></v-text-field>
            </div>
            <div
              v-if="
                [
                  '疼痛，部位',
                  '血壓不穩(血壓高/低)',
                  '咖啡色胃液',
                  '抽搐',
                  '尿濁',
                  '慢性腎臟疾病',
                  '失智症',
                  '陳舊性肺結核',
                  '思覺失調症',
                  '癌症',
                ].includes(option)
              "
              :key="index"
              style="width: 100%"
            ></div>
          </template>
        </v-btn-toggle>

        <v-col
          v-for="(subquestion, subquestionIndex) in question.subquestions"
          :key="subquestionIndex"
          cols="12"
        >
          <!-- 日期選擇器 -->
          <v-menu
            v-if="
              Array.isArray(question.answer) &&
              question.answer.includes(subquestion.question) &&
              subquestion.type === 'date'
            "
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="subquestion.answer"
                :label="subquestion.label"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="subquestion.answer"
              no-title
              scrollable
              dense
              show-adjacent-months
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-else-if="question.type === 'date'">
        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="question.answer"
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="question.answer"
            no-title
            scrollable
            dense
            show-adjacent-months
            absolute
            @change="calculateAge($event, question)"
          ></v-date-picker>
        </v-menu>
      </v-row>
      <div v-else-if="question.type === 'multifield'">
        <div v-if="question.subquestions">
          <v-row
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            <v-col v-for="(detail, detailIndex) in subquestion.details" :key="detailIndex">
              <v-row dense>
                <v-col>
                  <div class="subquestion-title">
                    <v-text-field
                      v-if="detail.type === 'textfield'"
                      v-model="detail.answer"
                      :label="detail.question"
                      :type="['收縮壓', '舒張壓'].includes(detail.question) ? 'number' : 'text'"
                      dense
                    ></v-text-field></div></v-col
                ><v-col
                  cols="1"
                  v-if="
                    question.question === '血壓' && detailIndex !== subquestion.details.length - 1
                  "
                >
                  /</v-col
                ></v-row
              ></v-col
            >
          </v-row>
        </div>
      </div>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'EmergencyContactComponent',
  props: {
    question: Object,
  },
  data() {
    return {
      formValid: false,
      showDatePicker: true,
      selectedDate: null,
      selectedTime: null,
      activeMenu: false,
    }
  },
  methods: {
    onDateSelected(date) {
      this.selectedDate = date
      this.showDatePicker = false
    },
    onTimeSelected(time, subquestion) {
      this.selectedTime = time
      const formattedDateTime = `${this.selectedDate} ${this.selectedTime}`
      subquestion.answer = formattedDateTime
      this.selectedDate = null
      this.selectedTime = null
      this.showDatePicker = true
      this.activeMenu = false
    },
    calculateAge(date, question) {
      const today = new Date()
      const birthDate = new Date(date)
      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDiff = today.getMonth() - birthDate.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      question.age = age
    },
  },
}
</script>

<style scoped>
/* v-btn-toggle 的背景會強制覆蓋 */
.v-btn-toggle:not(.v-btn-toggle--group) {
  background-color: antiquewhite;
}

.selected-option {
  background-color: #0078ff !important;
  color: white !important;
}

.level3 {
  font-size: 1em;
  font-weight: bold;
  color: #0b6100;
}
</style>

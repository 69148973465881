<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-toolbar dark color="#025464">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>填寫日誌 - {{ selectedCaseName }}</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-title>
        <v-row dense>
          <v-col class="text-center" cols="12">
            <span>個案姓名: </span>
            <span class="font-weight-bold">{{ selectedCaseName }}</span>
          </v-col>
          <v-col class="text-center" cols="12">
            <span>紀錄日期: </span>
            <span>{{ formattedDate }}</span>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-btn
              class="font-weight-bold black--text"
              color="#00e6a0"
              @click="getLastRecord(caseNum)"
            >
              匯入前次紀錄
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row dense v-for="(item, key) in contents" :key="key">
              <v-col cols="12">
                <span class="text-h5 font-weight-bold black--text">{{ item.question }}</span>
              </v-col>
              <v-col cols="12">
                <template v-if="item.options && item.options.length">
                  <v-btn-toggle dense v-model="item.answer" style="flex-wrap: wrap">
                    <v-btn
                      v-for="option in item.options"
                      :key="option"
                      :value="option"
                      color="#ffb200"
                      class="ma-1 text-h6 font-weight-bold"
                      active-class="selected-option"
                    >
                      {{ option }}
                    </v-btn>
                  </v-btn-toggle>
                </template>
                <v-textarea v-else dense filled clearable v-model="item.answer"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="font-weight-bold black--text">傷口紀錄</span>
              </v-col>
              <v-col
                v-for="(image, index) in imagePreviews"
                :key="index"
                cols="6"
                sm="4"
                md="3"
                lg="2"
              >
                <div class="gallery-item">
                  <v-img :src="image" alt="Uploaded Image" max-width="100%" />
                </div>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <div class="gallery-item">
                  <div class="upload-placeholder" @click="handleUpload">
                    <v-icon large>mdi-plus</v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-btn
              v-if="imagePreviews.length > 0"
              @click="submitUpload"
              color="primary"
              class="ma-2"
            >
              上傳測試
            </v-btn>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="mx-4" color="red darken-1" text @click="$emit('close')">取消</v-btn>
        <v-btn class="mx-4" color="blue darken-1" text @click="$emit('submit', contents)"
          >送出</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    selectedCaseName: String,
    formattedDate: String,
    caseNum: String,
    contents: Object,
    imagePreviews: Array,
  },
  methods: {
    getLastRecord(caseNum) {
      this.$emit('get-last-record', caseNum)
    },
    handleUpload() {
      this.$emit('upload-image')
    },
    submitUpload() {
      this.$emit('submit-upload')
    },
  },
}
</script>
<style scoped>
.selected-option {
  background-color: #0078ff !important;
  color: white !important;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ccc;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
}

.upload-placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

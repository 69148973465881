<template>
  <v-card color="#FFFBDA">
    <v-container>
      <div class="my-4 text-h5 font-weight-bold">日誌紀錄月報表匯出</div>
      <div class="my-2 text-h6 font-weight-bold blue--text">選擇月份</div>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            v-model="formattedDate"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details="true"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="selectedDate"
          type="month"
          locale="zh-TW"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
      <div class="my-2 text-h6 font-weight-bold blue--text">選擇個案</div>
      <v-autocomplete
        dense
        outlined
        v-model="selectedCases"
        :items="caseOptions"
        item-text="name"
        item-value="case_num"
        multiple
        chips
        deletable-chips
        clearable
        :loading="loading"
        :disabled="loading"
        no-data-text="無"
        hide-details="true"
      >
        <template v-slot:selection="{ item }">
          <v-chip
            class="white--text"
            color="#6A80B9"
            close
            @click:close="removeChip(item.case_num)"
          >
            {{ item.name }}
          </v-chip>
        </template>
      </v-autocomplete>

      <v-btn color="#F6C794" class="mt-4 font-weight-bold" @click="exportReport">匯出報表</v-btn>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  data() {
    return {
      menu: false,
      selectedDate: moment().format('YYYY-MM'),
      selectedCases: [],
      caseOptions: [],
      loading: false,
      downloading: false,
    }
  },
  computed: {
    formattedDate() {
      return this.selectedDate
    },
  },
  methods: {
    async fetchCaseOptions() {
      this.loading = true
      try {
        const response = await axios.get('/api/attendance/name_corresponding/')
        this.caseOptions = response.data
      } catch (error) {
        console.error('Error:', error)
      }
      this.loading = false
    },
    async exportReport() {
      if (!this.selectedCases.length) {
        alert('請選擇至少一個個案')
        return
      }
      this.downloading = true
      const requestBody = {
        cases: this.selectedCases,
        month: this.selectedDate,
        timezone: 'Asia/Taipei',
      }
      try {
        const response = await axios.post('/api/journal/monthly_report/', requestBody, {
          responseType: 'blob',
        })
        const fileName = `${this.selectedDate.replace('-', '')}_service_record.zip`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
        this.resetSelections()
      } catch (error) {
        console.error('匯出報表失敗:', error)
        alert('匯出失敗，請稍後再試！')
      } finally {
        this.downloading = false
      }
    },
    resetSelections() {
      this.selectedDate = moment().format('YYYY-MM')
      this.selectedCases = []
    },
  },
  mounted() {
    this.fetchCaseOptions()
  },
}
</script>

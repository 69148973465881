<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- MAIN_ID -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.MAIN_ID"
            label="主檔案 ID"
            :rules="[rules.required, rules.numeric]"
            maxlength="22"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            readonly
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- Q1 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q1"
            :items="nutritionQ1Options"
            label="過去三個月之中，是否因食慾不佳、消化問題、咀嚼或吞嚥困難，以致進食量減少？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q2 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q2"
            :items="nutritionQ2Options"
            label="近三個月體重變化"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q3 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q3"
            :items="nutritionQ3Options"
            label="行動力"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q4 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q4"
            :items="nutritionQ4Options"
            label="過去三個月內曾有精神性壓力或急性疾病發作？"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q5 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q5"
            :items="nutritionQ5Options"
            label="神經精神問題"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q6_TYPE -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q6_TYPE"
            :items="bmiCategoryOptions"
            label="體態標準"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>

        <!-- Q6 -->
        <v-col cols="12" md="6">
          <v-select
            outlined
            v-model="form.Q6"
            :items="bmiSubCategoryOptions"
            label="身體質量指數 (BMI)，若無法取得，用小腿圍或臂中圍代替"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pa-2" justify="center"
        ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
      >
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      isFormValid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q5: '',
        Q6_TYPE: '',
        Q6: '',
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
      nutritionQ1Options: [
        { text: '嚴重食慾不佳', value: '5' },
        { text: '中度食慾不佳', value: '10' },
        { text: '食慾無變差', value: '15' },
      ],
      nutritionQ2Options: [
        { text: '體重減輕>3公斤', value: '5' },
        { text: '不知道', value: '10' },
        { text: '體重減輕1-3公斤', value: '15' },
        { text: '體重無改變', value: '20' },
      ],
      nutritionQ3Options: [
        { text: '臥床或輪椅', value: '5' },
        { text: '可以下床活動或離開輪椅但無法自由活動', value: '10' },
        { text: '可以自由走動', value: '15' },
      ],
      nutritionQ4Options: [
        { text: '是', value: '5' },
        { text: '否', value: '10' },
      ],
      nutritionQ5Options: [
        { text: '嚴重認知障礙或抑鬱', value: '5' },
        { text: '輕度癡呆', value: '10' },
        { text: '無精神問題', value: '15' },
      ],
      bmiCategoryOptions: [
        { text: 'BMI', value: '5' },
        { text: '小腿圍(公分)', value: '10' },
        { text: '臂中圍(公分)', value: '15' },
      ],
      bmiSubCategoryOptions: [
        { text: 'BMI<19', value: '5' },
        { text: '19≤BMI<21', value: '10' },
        { text: '21≤BMI<23', value: '15' },
        { text: 'BMI≥23', value: '20' },
        { text: '小腿圍<31', value: '25' },
        { text: '小腿圍≥31', value: '30' },
        { text: '臂中圍<22', value: '35' },
        { text: '臂中圍≥22', value: '40' },
      ],
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q5: '',
        Q6_TYPE: '',
        Q6: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        console.log(this.form)
        try {
          const response = await axios.post('/api/mohw_assessment/qc111', this.form)

          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>

<template>
  <v-container class="mt-2">
    <v-row class="my-4">
      <v-col cols="6" sm="4">
        <v-menu v-model="carMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block color="#141E46" dark v-bind="attrs" v-on="on">車別切換</v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(car, index) in carList"
              :key="index"
              @click="selectCar(car.car_serial)"
            >
              <v-list-item-title>{{ car.car_serial }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col cols="6" sm="4">
        <v-btn block color="#141E46" dark @click="dialog = true">新增乘客</v-btn>
      </v-col>
    </v-row>

    <v-simple-table class="custom-table">
      <thead>
        <tr>
          <th class="header">車別</th>
          <th class="header">趟次</th>
          <th class="header">姓名</th>
          <th class="header">預計接送時段</th>
          <th class="header">刪除</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rider, index) in riders" :key="index">
          <td>{{ rider.car_serial }}</td>
          <td>{{ rider.trip }}</td>
          <td>{{ getPassengerName(rider.case_num) }}</td>
          <td>{{ rider.pick_up_time }}</td>
          <td>
            <v-btn icon color="red" @click="deletePassenger(rider.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">新增乘客</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="車別"
              v-model="selectedCarSerial"
              readonly
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-model="selectedCaseNum"
              :items="passengerList"
              item-text="name"
              item-value="case_num"
              label="個案姓名"
              dense
              outlined
              clearable
            ></v-autocomplete>
            <v-select
              v-model="selectedTrip"
              :items="tripOptions"
              label="選擇趟次"
              dense
              outlined
              clearable
            ></v-select>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="selectedHour"
                  :items="hourOptions"
                  label="時"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="selectedMinute"
                  :items="minuteOptions"
                  label="分"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="addPassenger">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      carMenu: false,
      dialog: false,
      selectedCarSerial: '',
      selectedCaseNum: null,
      selectedTrip: null,
      selectedHour: null,
      selectedMinute: null,
      carList: [],
      passengerList: [],
      tripOptions: [],
      riders: [],
      hourOptions: ['07', '08', '09', '17', '18', '19'],
      minuteOptions: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      nameMapping: {},
    }
  },
  methods: {
    async fetchCars() {
      const response = await axios.get('/api/traffic/shuttle/list/')
      this.carList = response.data.data
      if (this.carList.length > 0) {
        this.selectCar(this.carList[0].car_serial)
      }
    },
    async selectCar(carSerial) {
      this.selectedCarSerial = carSerial
      await this.fetchTrips()
      await this.fetchPassengers()
      await this.fetchRiders()
    },
    async fetchTrips() {
      const response = await axios.get(
        `/api/traffic/trip_option/?car_serial=${this.selectedCarSerial}`
      )
      this.tripOptions = response.data.data.map((item) => item.trip)
    },
    async fetchPassengers() {
      const response = await axios.get('/api/attendance/name_corresponding/')
      this.passengerList = response.data
      this.nameMapping = Object.fromEntries(this.passengerList.map((p) => [p.case_num, p.name]))
    },
    async fetchRiders() {
      const response = await axios.get(
        `/api/traffic/trip_management/list/?car_serial=${this.selectedCarSerial}`
      )
      this.riders = response.data.data
    },
    async addPassenger() {
      if (
        !this.selectedCaseNum ||
        !this.selectedTrip ||
        !this.selectedHour ||
        !this.selectedMinute
      ) {
        alert('請填寫所有欄位')
        return
      }

      const newPassenger = {
        case_num: this.selectedCaseNum,
        car_serial: this.selectedCarSerial,
        trip: this.selectedTrip,
        pick_up_time: `${this.selectedHour}:${this.selectedMinute}`,
      }

      await axios.post('/api/traffic/trip_management/create/', new URLSearchParams(newPassenger))

      this.dialog = false
      this.resetForm()
      await this.fetchRiders()
    },
    async deletePassenger(id) {
      await axios.post(`/api/traffic/trip_management/delete/${id}/`)
      await this.fetchRiders()
    },
    resetForm() {
      this.selectedCaseNum = null
      this.selectedTrip = null
      this.selectedHour = null
      this.selectedMinute = null
    },
    getPassengerName(caseNum) {
      return this.nameMapping[caseNum] || caseNum
    },
  },
  async mounted() {
    await this.fetchCars()
  },
}
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center !important;
  align-content: center;
}

.header {
  background-color: #96ff96;
  font-weight: bold;
  font-size: 20px !important;
}
</style>

<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'

export default {
  props: ['dataDailyHr', 'labelDailyHr'],
  data() {
    return {}
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'line', snap: true },
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: this.labelDailyHr,
          axisLabel: {
            interval: 59,
            formatter: (value, index) => {
              console.log(index)
              if (index % 60 === 0) {
                const hour = moment(value, 'HH:mm').format('HH')
                return `${hour}`
              } else {
                return ''
              }
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: 'value',
          position: 'left',
        },
        series: [
          {
            name: '當日心率變化',
            data: this.dataDailyHr,
            color: '#B70404',
            type: 'line',
            smooth: true,
            symbol: 'none',
          },
        ],
      }
      chart.setOption(option)
    },
  },
  watch: {
    dataDailyHr() {
      this.renderChart()
    },
    labelDailyHr() {
      console.log(this.labelDailyHr)
      this.renderChart()
    },
  },
}
</script>
<style scoped>
.chart {
  width: 600px;
  height: 400px;
}

@media (max-width: 600px) {
  .chart {
    width: 100vw;
    height: 100vw;
  }
}
</style>

<template>
  <v-container class="text-center">
    <v-row justify="center" align="center" class="my-2 px-2">
      <v-col cols="4">
        <v-card class="pa-1" color="#325288">
          <v-card-text class="pa-1 text-body-1 white--text font-weight-bold">
            已上車: {{ onboardCount }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-1" color="#24A19C">
          <v-card-text class="pa-1 text-body-1 white--text font-weight-bold">
            已下車: {{ offboardCount }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-1" color="#D96098">
          <v-card-text class="pa-1 text-body-1 white--text font-weight-bold">
            未上車: {{ yetCount }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="pa-3 ma-2">
      <v-row justify="center" align="center" class="pt-3">
        <v-col cols="12" sm="6">
          <v-card class="pa-0" color="yellow lighten-3">
            <v-card-text class="pa-1 text-h6 black--text font-weight-bold">
              車別: {{ busType }} &nbsp;&nbsp; 趟次: {{ trip }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-btn height="40px" color="#37545d" dark @click="busTypeDialog = true"> 車別切換 </v-btn>
        </v-col>
        <v-col cols="6" sm="3">
          <v-btn height="40px" color="#5d4037" dark @click="tripDialog = true"> 趟次切換 </v-btn>
        </v-col>
      </v-row>
      <v-divider />

      <v-row align="center" class="my-4">
        <v-col cols="6" v-for="(passenger, index) in passengers" :key="index">
          <v-card
            class="pa-2"
            :color="getPassengerColor(passenger.state)"
            @click="toggleSelect(passenger.case_num)"
          >
            <v-card-text
              class="pa-2 text-h6 font-weight-bold white--text d-flex justify-center align-center"
            >
              {{ passenger.name }}
              <v-icon
                v-if="selectedPassengers.includes(passenger.case_num)"
                color="white"
                class="px-2"
                >mdi-check-circle</v-icon
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider />

      <v-row justify="center" class="pb-3">
        <v-col cols="6">
          <v-btn color="#0b6952" dark large @click="updateAttendance('get_on')">上車</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="#690B22" dark large @click="updateAttendance('get_off')">下車</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="busTypeDialog" max-width="300px">
      <v-card>
        <v-list>
          <v-list-item v-for="(bus, index) in busList" :key="index" @click="selectBusType(bus)">
            <v-list-item-content>
              <v-list-item-title>{{ bus }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="tripDialog" max-width="300px">
      <v-card>
        <v-list>
          <v-list-item v-for="(t, index) in tripList" :key="index" @click="selectTrip(t)">
            <v-list-item-content>
              <v-list-item-title>{{ t }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      busType: '',
      trip: '',
      passengers: [],
      selectedPassengers: [],
      busList: [],
      tripList: [],
      busTypeDialog: false,
      tripDialog: false,
    }
  },
  computed: {
    onboardCount() {
      return this.passengers.filter((p) => p.state === 'onboard').length
    },
    offboardCount() {
      return this.passengers.filter((p) => p.state === 'offboard').length
    },
    yetCount() {
      return this.passengers.filter((p) => p.state !== 'onboard' && p.state !== 'offboard').length
    },
  },
  async created() {
    await this.fetchBusTypes()
  },
  methods: {
    async fetchBusTypes() {
      try {
        const response = await fetch('/api/traffic/shuttle/list/')
        const data = await response.json()
        if (data.success === 'success') {
          this.busList = data.data.map((bus) => bus.car_serial)
          if (this.busList.length > 0) {
            this.busType = this.busList[0]
            await this.fetchTrips()
          }
        }
      } catch (error) {
        console.error('獲取車輛列表失敗:', error)
      }
    },
    async fetchTrips() {
      if (!this.busType) return
      try {
        const response = await fetch(`/api/traffic/list_passenger/${this.busType}/`)
        const data = await response.json()
        if (data.status === 'success') {
          this.tripList = data.data.map((trip) => trip.trip)
          if (this.tripList.length > 0) {
            this.trip = this.tripList[0]
            await this.fetchPassengers()
          }
        }
      } catch (error) {
        console.error('獲取趟次失敗:', error)
      }
    },
    async fetchPassengers() {
      if (!this.busType || !this.trip) return
      const date = new Date().toISOString().split('T')[0]
      try {
        const response = await fetch(
          `/api/traffic/list_passenger/${this.busType}/${this.trip}/?date=${date}`
        )
        const data = await response.json()
        if (data.status === 'success') {
          this.selectedPassengers = []
          this.passengers = data.data
        }
      } catch (error) {
        console.error('獲取乘客列表失敗:', error)
      }
    },
    selectBusType(bus) {
      this.busType = bus
      this.busTypeDialog = false
      this.fetchTrips()
    },
    selectTrip(t) {
      this.trip = t
      this.tripDialog = false
      this.fetchPassengers()
    },
    getPassengerColor(state) {
      return state === 'onboard' ? '#325288' : state === 'offboard' ? '#24A19C' : '#D96098'
    },
    toggleSelect(caseNum) {
      const index = this.selectedPassengers.indexOf(caseNum)
      if (index === -1) {
        this.selectedPassengers.push(caseNum)
      } else {
        this.selectedPassengers.splice(index, 1)
      }
      console.log('Selected Passengers:', this.selectedPassengers)
    },
    async updateAttendance(state) {
      if (this.selectedPassengers.length === 0) return
      const firstPassenger = this.passengers.find((p) =>
        this.selectedPassengers.includes(p.case_num)
      )
      if (!firstPassenger) return

      const payload = {
        case_numbers: this.selectedPassengers,
        trip: this.trip,
        car_serial: this.busType,
        datetime: new Date().toISOString(),
        state,
        latitude:
          state === 'get_on'
            ? firstPassenger.departure_latitude
            : firstPassenger.destination_latitude,
        longitude:
          state === 'get_on'
            ? firstPassenger.departure_longitude
            : firstPassenger.destination_longitude,
      }

      try {
        await fetch('/api/traffic/traffic_attendance/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        })
        this.selectedPassengers = []
        this.fetchPassengers()
      } catch (error) {
        console.error('送出簽到失敗:', error)
      }
    },
  },
}
</script>

<style scoped>
.v-btn {
  width: 100%;
}
.selected-card {
  border: 4px solid #000000 !important;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
})

Vue.config.productionTip = false
const siteMapping = process.env.VUE_APP_SITE_MAPPING || {}
const siteName = siteMapping[window.location.hostname] || 'AST'
// console.log('Site Mapping:', siteMapping)
// console.log('Current Site Name:', siteName)
new Vue({
  router,
  store,
  vuetify,
  provide: {
    siteName,
  },
  render: (h) => h(App),
}).$mount('#app')

<template>
  <div class="custom-margin">
    <v-row dense class="ma-2" align="center">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate"> 今日 </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn tile color="#BB2525" class="white--text" @click="prevDay" elevation="0">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn tile color="#BB2525" class="white--text" @click="menu = !menu" elevation="0">
              {{ formattedDate }}
            </v-btn>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn tile color="#BB2525" class="white--text" @click="nextDay" elevation="0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
      <div>
        <v-select
          dense
          outlined
          placeholder="選擇機構"
          :items="institutionList"
          v-model="selectedInstitution"
          class="pa-2"
          hide-details=""
          @change="onInstitutionChange"
        ></v-select>
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="filteredRecords"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="hover-effect">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.check_in`]="{ item, index }">
          <span
            v-if="item.check_in == null"
            @click="manual_check(index, 'in')"
            class="text-add hover-add"
            @mouseover="colorItems.check_in = '#258525'"
            @mouseout="colorItems.check_in = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect">
            {{ covertToLocalTime(item.check_in) }}
          </span>
        </template>
        <template v-slot:[`item.check_out`]="{ item, index }">
          <span
            v-if="item.check_out == null"
            @click="manual_check(index, 'out')"
            class="text-add hover-add"
            @mouseover="colorItems.check_out = '#258525'"
            @mouseout="colorItems.check_out = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect">
            {{ covertToLocalTime(item.check_out) }}
          </span>
        </template>
        <template v-slot:[`item.temperature`]="{ item, index }">
          <span
            v-if="item.temperature == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.temperature = '#258525'"
            @mouseout="colorItems.temperature = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.temperature }}
          </span>
        </template>
        <template v-slot:[`item.hr`]="{ item, index }">
          <span
            v-if="item.hr == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.hr = '#258525'"
            @mouseout="colorItems.hr = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.hr }}
          </span>
        </template>
        <template v-slot:[`item.bloodPressure`]="{ item, index }">
          <span
            v-if="item.shrink == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.shrink = '#258525'"
            @mouseout="colorItems.shrink = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.shrink }}/{{ item.relax }}
          </span>
        </template>
        <template v-slot:[`item.SPO2`]="{ item, index }">
          <span
            v-if="item.SPO2 == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.SPO2 = '#258525'"
            @mouseout="colorItems.SPO2 = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.SPO2 }}
          </span>
        </template>
        <template v-slot:[`item.blood_sugar`]="{ item, index }">
          <span
            v-if="item.blood_sugar == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.blood_sugar = '#258525'"
            @mouseout="colorItems.blood_sugar = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.blood_sugar }}
          </span>
        </template>
        <template v-slot:[`item.weight`]="{ item, index }">
          <span
            v-if="item.weight == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.weight = '#258525'"
            @mouseout="colorItems.weight = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="showVitalSignDialog(index)">
            {{ item.weight }}
          </span>
        </template>
        <template v-slot:[`item.history`]="{ item, index }">
          <v-btn @click="viewVitalSignHistory(index)" class="hover-effect"> 查看 </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog persistent v-model="sign_dialog" max-width="400px">
      <v-card>
        <v-card-title class="justify-center"> 簽到退紀錄 - {{ caseName }} </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                dense
                outlined
                :items="select_item"
                v-model="sign_status"
                :value="getSignItemValue(sign_status)"
                class="pa-2"
                hide-details=""
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="選擇日期"
                v-model="date"
                type="date"
                prepend-icon="mdi-calendar"
                :rules="dateRules"
                class="my-2"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="選擇時間"
                v-model="time"
                type="time"
                prepend-icon="mdi-clock-time-four-outline"
                :rules="timeRules"
                class="my-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="sign_dialog = false">取消</v-btn>
            <v-btn text color="blue" @click="onCheck">確認</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="justify-center">
          {{ caseName }} 的健康數據
          <v-spacer />
          <v-btn v-if="exist" color="#614BC3" class="white--text" @click="enterVitalSign(index)">
            ✚新增資料
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <!-- <v-text-field
              filled
              label="時間"
              :value="time"
              readonly
              hide-details=""
              class="my-2"
            ></v-text-field> -->
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="選擇日期"
                  v-model="date"
                  type="date"
                  prepend-icon="mdi-calendar"
                  :rules="dateRules"
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="選擇時間"
                  v-model="time"
                  type="time"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="timeRules"
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="體溫"
                  v-model="temperature"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="心率"
                  v-model="hr"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="收縮壓"
                  v-model="shrink"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="舒張壓"
                  v-model="relax"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="血氧"
                  v-model="spo2"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="血糖"
                  v-model="bs"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="體重"
                  v-model="weight"
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <!-- <v-btn text color="red" @click="dialog = false">拒測</v-btn> -->
            <v-btn v-if="exist" text color="blue" @click="dialog = false">確認</v-btn>
            <v-btn v-else text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="history_dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          {{ caseName }} 的歷史健康數據
          <v-spacer />
          <v-btn color="blue darken-1" text @click="history_dialog = false">關閉</v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row
              dense
              :class="['default-border', isEditing(record) ? 'editing-border' : '']"
              v-for="record in vitalSignHistory"
              :key="record.vital_sign_id"
              class="relative-position pa-2 my-2"
            >
              <!-- 編輯按鈕 -->
              <v-btn
                icon
                small
                class="edit-btn"
                :class="{ 'confirm-btn': isEditing(record) }"
                @click="toggleEdit(record)"
              >
                <v-icon>{{ isEditing(record) ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
              </v-btn>

              <!-- 紀錄時間不可修改 -->
              <v-col cols="12">
                <v-text-field
                  label="紀錄時間"
                  :value="formatDate(record.record_datetime)"
                  prepend-icon="mdi-calendar"
                  readonly
                  class="my-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.temperature !== null">
                <v-text-field
                  dense
                  outlined
                  label="體溫"
                  v-model="record.temperature"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.hr !== null">
                <v-text-field
                  dense
                  outlined
                  label="心率"
                  v-model="record.hr"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.shrink !== null && record.relax !== null">
                <v-text-field
                  dense
                  outlined
                  label="收縮壓"
                  v-model="record.shrink"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.shrink !== null && record.relax !== null">
                <v-text-field
                  dense
                  outlined
                  label="舒張壓"
                  v-model="record.relax"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.SPO2 !== null">
                <v-text-field
                  dense
                  outlined
                  label="血氧"
                  v-model="record.SPO2"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.blood_sugar !== null">
                <v-text-field
                  dense
                  outlined
                  label="血糖"
                  v-model="record.blood_sugar"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.weight !== null">
                <v-text-field
                  dense
                  outlined
                  label="體重"
                  v-model="record.weight"
                  :readonly="!isEditing(record)"
                  :flat="!isEditing(record)"
                  hide-details="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import qs from 'qs'
export default {
  data() {
    return {
      headers: [
        {
          text: '姓名',
          value: 'name',
          align: 'center',
          width: '100px',
        },
        { text: '簽到', value: 'check_in', align: 'center', width: '75px' },
        { text: '簽退', value: 'check_out', align: 'center', width: '75px' },
        { text: '體溫', value: 'temperature', align: 'center', width: '75px' },
        { text: '心率', value: 'hr', align: 'center', width: '75px' },
        {
          text: '血壓',
          value: 'bloodPressure',
          align: 'center',
          width: '75px',
        },
        { text: '血氧', value: 'SPO2', align: 'center', width: '75px' },
        { text: '血糖', value: 'blood_sugar', align: 'center', width: '75px' },
        { text: '體重', value: 'weight', align: 'center', width: '75px' },
        { text: '歷史紀錄', value: 'history', align: 'center', width: '75px' },
      ],
      records: [],
      readonly: false,
      sign_dialog: false,
      dialog: false,
      exist: false,
      staff_id: '',
      case_id: '',
      index: '',
      caseName: '',
      date: '',
      time: '',
      temperature: '',
      hr: '',
      shrink: '',
      relax: '',
      bs: '',
      spo2: '',
      weight: '',
      colorItems: {
        check_in: '#8d8d8d',
        check_out: '#8d8d8d',
        temperature: '#8d8d8d',
        hr: '#8d8d8d',
        shrink: '#8d8d8d',
        bloodOxygen: '#8d8d8d',
        blood_sugar: '#8d8d8d',
        weight: '#8d8d8d',
      },
      menu: false,
      selectedDate: new Date(),
      dateRules: [(v) => !!v || '此欄位不可為空'],
      timeRules: [(v) => !!v || '此欄位不可為空'],
      select_item: ['簽到', '簽退'],
      sign_status: '',
      sign_action: '', // 簽到與簽退與後端溝通的動作
      vitalSignHistory: [],
      history_dialog: false,

      // 篩選機構
      institutionList: [],
      selectedInstitution: null,
      filteredRecords: [],
      editingRecord: null,
    }
  },
  methods: {
    async getList() {
      // console.log(this.formattedDate);
      let config = {
        method: 'get',
        url: `/api/attendance/list_vital_sign?date=${this.formattedDate}`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        console.log(res.data.data)
        this.records = res.data.data
        this.filteredRecords = this.records
        this.institutionList = [...new Set(res.data.data.map((item) => item.institution))]
        this.institutionList.unshift('全部')
      } catch (error) {
        console.log(error)
      }
      this.onInstitutionChange()
    },
    onInstitutionChange() {
      if (this.selectedInstitution === '全部') {
        this.filteredRecords = this.records
      } else if (this.selectedInstitution) {
        this.filteredRecords = this.records.filter(
          (item) => item.institution === this.selectedInstitution
        )
      } else {
        this.filteredRecords = this.records
      }
    },
    enterVitalSign(index) {
      this.index = index
      this.getCurrentTime()
      this.caseName = this.filteredRecords[index].name
      this.case_id = this.filteredRecords[index].case_num
      this.temperature = ''
      this.hr = ''
      this.shrink = ''
      this.relax = ''
      this.spo2 = ''
      this.bs = ''
      this.weight = ''
      this.exist = false
      this.readonly = false
      this.dialog = true
    },
    showVitalSignDialog(index) {
      this.index = index
      this.caseName = this.filteredRecords[index].name
      this.case_id = this.filteredRecords[index].case_num
      console.log(this.filteredRecords[index].record_datetime)
      let dateTime = new Date(this.filteredRecords[index].record_datetime)
      let localDateTime = moment.utc(dateTime).local()
      this.date = localDateTime.format('YYYY-MM-DD')
      this.time = localDateTime.format('HH:mm')
      this.temperature = this.filteredRecords[index].temperature
      this.hr = this.filteredRecords[index].hr
      this.shrink = this.filteredRecords[index].shrink
      this.relax = this.filteredRecords[index].relax
      this.spo2 = this.filteredRecords[index].SPO2
      this.bs = this.filteredRecords[index].blood_sugar
      this.weight = this.filteredRecords[index].weight
      this.exist = true
      this.readonly = true
      this.dialog = true
    },
    manual_check(index, status) {
      this.index = index
      this.getCurrentTime()
      this.caseName = this.filteredRecords[index].name
      this.case_id = this.filteredRecords[index].case_num
      if (status == 'in') {
        this.sign_action = 'check_in'
        this.sign_status = '簽到'
      } else {
        this.sign_action = 'check_out'
        this.sign_status = '簽退'
      }
      this.sign_dialog = true
    },
    async onCheck() {
      if (this.sign_status === '簽到') {
        this.sign_action = 'check_in'
      } else {
        this.sign_action = 'check_out'
      }

      this.case_id = this.filteredRecords[this.index].case_num
      let formattedTime = moment(`${this.date}T${this.time}`).format()
      let data = qs.stringify({
        case_num: this.case_id,
        datetime: formattedTime,
        action: this.sign_action,
      })
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/attendance/manual_check',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      }
      try {
        let res = await axios.request(config)
        this.getList()
        this.sign_dialog = false
      } catch (error) {
        alert(error.response.data.data)
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let formattedTime = moment(`${this.date}T${this.time}`).format()
        let data = qs.stringify({
          action: 'create',
          employee_id: this.staff_id,
          temperature: this.temperature,
          hr: this.hr,
          shrink: this.shrink,
          relax: this.relax,
          blood_sugar: this.bs,
          SPO2: this.spo2,
          weight: this.weight,
          datetime: formattedTime,
        })
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `/api/attendance/vitalsigns/${this.case_id}/`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: data,
        }
        try {
          let res = await axios.request(config)
          console.log(res.data)
        } catch (error) {
          console.log(error)
        }
      }
      this.getList()
      this.dialog = false
    },
    async viewVitalSignHistory(index) {
      this.history_dialog = true
      this.index = index
      this.caseName = this.filteredRecords[index].name
      this.case_id = this.filteredRecords[index].case_num
      let config = {
        method: 'get',
        url: `/api/attendance/vitalsigns/history/${this.case_id}/`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        console.log(res.data.data)
        this.vitalSignHistory = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async editVitalSign(record) {
      const data = qs.stringify({
        temperature: record.temperature,
        hr: record.hr,
        shrink: record.shrink,
        relax: record.relax,
        blood_sugar: record.blood_sugar,
        SPO2: record.SPO2,
        weight: record.weight,
        employee_id: record.employee_id,
      })
      try {
        const response = await axios.post(
          `/api/attendance/vitalsigns/${this.case_id}/edit/${record.vital_sign_id}/`,
          data
        )
        console.log(response.data)
      } catch (error) {
        console.log(`資料更新失敗：${error.response?.data?.message || error.message}`)
      }
    },
    getCurrentTime() {
      // 用 moment 處理 ISO8601 的字串
      // 分開處理日期與時間，因為日期與時間是分開的欄位
      // 日期會跟著選擇的日期變動
      // 時間基本上跟著本地時間走（夏令時間可能會有差異，但因為只用於顯示，不影響實際輸入時間）
      this.date = moment(this.selectedDate.toISOString()).format('YYYY-MM-DD')
      this.time = moment(new Date()).format('HH:mm')
      console.log(this.date + ' ' + this.time)
    },
    getCurrentDate() {
      this.selectedDate = new Date()
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1)
      this.selectedDate = new Date(this.selectedDate)
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1)
      this.selectedDate = new Date(this.selectedDate)
    },
    covertToLocalTime(time) {
      return moment(time).format('HH:mm')
    },
    formatDate(dateStr) {
      const date = new Date(dateStr)
      return date
        .toLocaleString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
        .replace(',', '') // 移除逗號
    },
    getSignItemValue(item) {
      return item === '簽到' ? 'check_in' : 'check_out'
    },
    isEditing(record) {
      return this.editingRecord === record
    },
    toggleEdit(record) {
      if (this.editingRecord === record) {
        this.editVitalSign(record)
        this.editingRecord = null
      } else {
        this.editingRecord = record
      }
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear()
        const month = this.selectedDate.getMonth() + 1
        const day = this.selectedDate.getDate()
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
      },
      set(newValue) {
        const [year, month, day] = newValue.split('-').map(Number)
        this.selectedDate = new Date(year, month - 1, day)
      },
    },
  },
  mounted() {
    this.getList()
  },
  watch: {
    selectedDate() {
      this.getList()
    },
  },
  created() {
    let staff = sessionStorage.getItem('staff')
    this.staff_id = JSON.parse(staff).id
  },
}
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}

.default-border {
  border: 2px solid gray;
  border-radius: 5px;
}

.editing-border {
  border: 4px solid #1976d2 !important;
  transition: border 0.2s ease-in-out;
}

.relative-position {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.confirm-btn {
  background-color: #4caf50 !important;
  color: white !important;
}
</style>

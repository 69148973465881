<template>
  <v-container class="custom-margin">
    <v-card class="contact-card">
      <v-card-title class="justify-center custom-title">急診聯繫單</v-card-title>
      <v-card-subtitle class="mx-4 px-4 py-1 text-right font-weight-bold text-h6 black--text"
        >填表日期: {{ formattedDate }}</v-card-subtitle
      >
      <v-form>
        <v-container>
          <!-- 第一層主標題（送醫前必要資訊、此次就醫、基本資料、身體評估） -->
          <v-row dense v-for="(content, key) in contents" :key="key">
            <v-container class="level1" v-if="content.title !== '填表日期'">
              {{ content.title }}

              <!-- 第二層標題 (description) -->
              <v-container v-for="(section, contentIndex) in content.sections" :key="contentIndex">
                <v-col cols="12" justify="center">
                  <span class="custom-section">
                    {{ section.description }}
                  </span>
                </v-col>

                <v-col class="content-padding" justify="center">
                  <v-row dense :key="contentIndex">
                    <!-- 第三層之後，使用元件處理 -->
                    <v-col
                      v-for="(question, questionIndex) in section.questions"
                      :key="questionIndex"
                      class="content-question"
                      :cols="12"
                      :sm="question.size > 6 ? question.size : 6"
                      :md="question.size"
                    >
                      <!-- TODO: border 拿掉 -->
                      <EmergencyContactComponent :question="question"></EmergencyContactComponent>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-container>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn x-large class="mx-8 my-4 white--text" color="blue darken-1" @click="submitForm"
          >預覽填答內容</v-btn
        >
        <!-- <v-btn class="ma-4 white--text" color="blue darken-1" @click="submitForm">送出</v-btn> -->
        <v-btn x-large class="mx-8 my-4 white--text" color="red darken-1" @click="clearAnswers"
          >清空表單</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">以下項目為必填：</v-card-title>
        <v-card-text>
          <ul>
            <li class="text-h6" v-for="(item, index) in missingAnswers" :key="index">
              {{ item }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" text @click="dialog = false">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewDialog">
      <v-card class="preview-card">
        <v-card-text class="pa-2 preview-content">
          <div style="position: relative; height: 100%">
            <iframe :src="`${pdfUrl}#toolbar=0`" class="preview-pdf"></iframe>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pa-2 preview-actions">
          <v-btn
            x-large
            class="mx-8 my-1 white--text"
            color="red darken-1"
            @click="previewDialog = false"
            >返回修改</v-btn
          >
          <v-btn x-large class="mx-8 my-1 white--text" color="teal" @click="uploadForm"
            >確認送出</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import EmergencyContactComponent from '@/components/EmergencyContactComponent.vue' // 引入剛創建的子組件
export default {
  name: 'EmergencyContact',
  components: { EmergencyContactComponent },
  data() {
    return {
      headers: [],
      formattedDate: '',
      contents: [],
      missingAnswers: [],
      dialog: false,
      previewDialog: false,
      pdfUrl: null,
    }
  },
  methods: {
    async getQuestions() {
      let config = {
        method: 'get',
        url: `/api/journal/get_emergency_contact_question?name=EMER_001`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        this.contents = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    submitForm() {
      let missingAnswers = []
      let isValid = true
      const exemptedQuestions = []
      const requiredQuestions = []
      const contentsCopy = this.contents.slice(1)

      contentsCopy.forEach((form) => {
        form.sections.forEach((section) => {
          section.questions.forEach((question) => {
            // 處理例外問題
            if (question.question === '量不到(可複選)' && Array.isArray(question.answer)) {
              exemptedQuestions.push(...question.answer)
            }

            // 標記所有必填問題
            if (question.required) {
              requiredQuestions.push(question.question)
            }
          })
        })
      })

      contentsCopy.forEach((form) => {
        form.sections.forEach((section) => {
          section.questions.forEach((question) => {
            // 驗證一般必填問題
            if (
              requiredQuestions.includes(question.question) &&
              !exemptedQuestions.includes(question.question)
            ) {
              if (question.answer === null || question.answer === '') {
                isValid = false
                missingAnswers.push(question.question)
              }
            }

            // 檢查 "醫療決策者／代理人" 和 "血壓" 是否填寫完整
            if (['醫療決策者／代理人', '血壓'].includes(question.question)) {
              let isAnyComplete = false
              question.subquestions.forEach((sub) => {
                const isComplete =
                  sub.details?.every(
                    (detail) => detail.answer !== null && detail.answer.trim() !== ''
                  ) ?? false
                if (isComplete) {
                  isAnyComplete = true
                  missingAnswers = missingAnswers.filter((answer) => answer !== question.question)
                }
              })
            }

            // 檢查過敏史
            if (question.question === '過敏史' && question.answer === true) {
              const allergyDetails = question.subquestions?.[0]?.details || []
              const hasAnyAllergy = allergyDetails.some(
                (detail) => detail.answer !== null && detail.answer.trim() !== ''
              )

              if (!hasAnyAllergy) {
                isValid = false
                missingAnswers.push('請填寫至少一項過敏史 (藥物、食物、其他)')
              }
            }

            // 檢查飲食狀況
            if (question.question === '飲食狀況' && question.answer === true) {
              const lastMealOptions = question.subquestions || []

              let hasValidMealTime = false
              lastMealOptions.forEach((sub) => {
                if (['最後進食時間(由口進食)', '最後進食時間(管灌)'].includes(sub.question)) {
                  const mealTimeDetails = sub.details || []
                  const isMealTimeComplete = mealTimeDetails.every(
                    (detail) => detail.answer !== null && detail.answer.trim() !== ''
                  )

                  if (isMealTimeComplete) {
                    hasValidMealTime = true
                  }
                }
              })

              if (!hasValidMealTime) {
                isValid = false
                missingAnswers.push('請至少填寫一組完整的最後進食時間 (月、日、時)')
              }
            }
          })
        })
      })

      // 顯示錯誤訊息或提交表單
      if (missingAnswers.length > 0) {
        this.missingAnswers = missingAnswers
        this.dialog = true
      } else {
        this.contents[0].date = this.formattedDate
        this.previewForm()
      }
    },
    async previewForm() {
      try {
        const response = await axios.post(
          '/api/journal/preview_emergency_contact_pdf',
          this.contents,
          {
            responseType: 'arraybuffer',
          }
        )
        if (response.status === 200) {
          if (this.pdfUrl) {
            URL.revokeObjectURL(this.pdfUrl)
          }
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          this.pdfUrl = url
          this.previewDialog = true
        } else {
          throw new Error('取得PDF失敗')
        }
      } catch (error) {
        console.log(`取得PDF失敗：${error.response?.data?.message || error.message}`)
      }
    },
    async uploadForm() {
      try {
        const response = await axios.post('/api/journal/emergency_contact', this.contents, {
          responseType: 'blob',
        })
        if (response.status === 200) {
          const contentDisposition = response.headers['content-disposition']
          let filename = ''
          const match = contentDisposition && contentDisposition.match(/filename="(.+?)"/)
          if (match && match[1]) {
            filename = match[1]
          }
          const previewUrl = `/api/media/pdf/preview/${filename}`
          console.log(previewUrl)
          window.open(previewUrl, '_blank')
          this.clearAnswers()
        } else {
          throw new Error('資料上傳失敗')
        }
      } catch (error) {
        console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
      }
    },
    clearAnswers() {
      window.location.reload()
    },
    setCurrentDate() {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Taipei' }
      const currentDate = new Intl.DateTimeFormat('zh-TW', options).format(new Date())
      this.formattedDate = currentDate
    },
  },
  mounted() {
    this.getQuestions()
    this.setCurrentDate()
  },
  watch: {},
  created() {
    // let staff = sessionStorage.getItem('staff')
    // this.staff_id = JSON.parse(staff).id
  },
}
</script>
<style scoped>
/* 整體畫面初始設定 */
.custom-margin {
  overflow-x: auto !important;
  overflow-y: hidden;
  max-width: fit-content;
  max-width: 100%;
  width: 100%;
  font-size: 16px; /* Set default size is 16 px*/
}

/* 表單卡片樣式設定 (最外層) */
.contact-card {
  border-radius: 20px;
  margin: 1em;
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
  background-color: antiquewhite;
}

/* 表單名稱設定：急診聯繫單 */
.custom-title {
  color: #0079ff;
  font-weight: bold;
  font-size: 2em;
}

.level1 {
  font-size: 1.5em;
  font-weight: bold;
  color: #336ca4;
}

.level2 {
  font-size: 1em;
  font-weight: bold;
  color: #34495e;
}

.custom-section {
  text-align: center;
  color: black;
}

.content-question {
  border: 1px solid gray;
  border-radius: 20px;
}

/* 表單內容的padding */
.content-padding {
  padding: 1rem;
}

.preview-card {
  max-height: 90vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.preview-content {
  flex: 1;
  overflow: hidden;
}

.preview-actions {
  flex: 0;
}

.preview-pdf {
  height: 100%;
  width: 100%;
}

/* 以下為 RWD 設定 */

/* 桌面設定 */
@media (min-width: 1024px) {
  .custom-margin {
    width: 80%;
  }
}

/* 平板設定 */
@media (max-width: 1024px) {
  .custom-title {
    font-size: 1.5em;
  }
  .level1 {
  }
  .level2 {
  }
}

/* 手機設定(直式) */
@media (max-width: 768px) {
  .custom-title {
    font-size: 1.5em;
  }
  .level1 {
    font-size: 1.25em;
  }
  .level2 {
    font-size: 1em;
  }
}
</style>

<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          本月
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevMonth"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn tile color="#BB2525" class="white--text" elevation="0">
              {{ formattedDate }}
            </v-btn>
             </v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextMonth"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
      <v-col>
        <v-combobox
          clearable
          chips
          multiple
          label="選擇個案"
          variant="solo-filled"
          :items="idNumberChoiceList"
          item-text="name"
          item-value="id_number"
          v-model="selectedCases"
        >
      </v-combobox>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="filterCases">
          篩選個案
        </v-btn>
      </v-col>
      <div class="mx-2">
        <v-btn
          tile
          color="#813d67"
          class="white--text"
          elevation="0"
          @click="exportReport"
        >
          匯出核銷報表
        </v-btn>
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
    <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, itemIndex) in items" :key="`row-${itemIndex}`">
              <td
                v-for="(header, index) in headers"
                :key="`cell-${itemIndex}-${index}`"
                class="text-add hover-add"
                @mouseover="showTooltip(item, header.value, itemIndex, $event)"
                @mouseleave="hideTooltip"
              >

                {{ item[header.value] != null ? item[header.value] : 'x' }}

                <!-- Show Tooltip data -->
                <div v-if="tooltipVisible && header.value === currentHeader && itemIndex === currentItemIndex"
                    :style="{ left: tooltipX + 'px', top: tooltipY + 'px' }" 
                    class="tooltip">
                  {{ tooltipContent }}
                </div>

              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

      // column headers
      headers: [
      { text: '個案姓名', value: 'name' },
      { text: '服務日期', value: 'service_date' },
      { text: '服務項目代碼', value: 'service_code' },
      { text: '服務類別', value: 'service_category' },
      { text: '數量', value: 'quantity' },
      { text: '單價', value: 'unit_price' },
      { text: '服務人員', value: 'staff_name' },
      { text: '起始時段-小時(24小時制)', value: 'start_hour' },
      { text: '起始時段-分鐘', value: 'start_minute' },
      { text: '結束時段-小時(24小時制)', value: 'end_hour' },
      { text: '結束時段-分鐘', value: 'end_minute' },
      { text: '備註', value: 'remarks' },
      { text: 'BD03、DA01使用-出發地', value: 'departure_location' },
      { text: 'BD03、DA01使用-目的地', value: 'destination' },
      { text: 'BD03、DA01使用-出發地(經度)', value: 'departure_longitude' },
      { text: 'BD03、DA01使用-出發地(緯度)', value: 'departure_latitude' },
      { text: 'BD03、DA01使用-目的地(經度)', value: 'destination_longitude' },
      { text: 'BD03、DA01使用-目的地(緯度)', value: 'destination_latitude' },
      { text: 'BD03、DA01使用-里程數(公里)', value: 'mileage' },
      { text: 'BD03、DA01使用-車號', value: 'vehicle_number' },
      { text: 'BD03必填-服務使用類型', value: 'service_use_type' }
      ],
      records: [
        // {
        //   id_number: '',
        //   service_date: '',
        //   service_code: '',
        //   service_category: 1,
        //   quantity: 1,
        //   unit_price: 70,
        //   staff_id: 'A123456789',
        //   start_hour: 8,
        //   start_minute: 0,
        //   end_hour: 10,
        //   end_minute: 30,
        //   remarks: '我是備註示範4',
        //   staff_id_2: '',
        //   staff_id_3: '',
        //   staff_id_4: '',
        //   staff_id_5: '',
        //   no_report_aa09: '',
        //   visit_not_encountered: '',
        //   recovery_target_status: '',
        //   recovery_target: '',
        //   guidance_target: '',
        //   service_content: '',
        //   guidance_summary: '',
        //   meal_type: '',
        //   departure_location: '出發地址',
        //   destination: '目的地址',
        //   departure_longitude: 121.56415,
        //   departure_latitude: 25.03349,
        //   destination_longitude: 121.56415,
        //   destination_latitude: 25.03349,
        //   mileage: 10.5,
        //   vehicle_number: 'DA-666',
        //   service_use_type: ''
        // }
      ],

      // data
      selectedDate: new Date(),  // 現在日期

      // 已選擇的個案清單
      selectedCases: [],
      filteredRecords: [],
      selectedNumbers: [],

      // Item 顏色
      colorItems: {
        check_in: "#8d8d8d",
        check_out: "#8d8d8d",
        temperature: "#8d8d8d",
        hr: "#8d8d8d",
        shrink: "#8d8d8d",
        bloodOxygen: "#8d8d8d",
        blood_sugar: "#8d8d8d",
        weight: "#8d8d8d",
      },

      // tooltip 資料
      tooltipVisible: false,
      tooltipContent: '',
      tooltipX: 0,
      tooltipY: 0,
      currentHeader: '',
      currentItemIndex: null,
    };
  },
  methods: {
    async getList() {
      console.log("getList");
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/get_reimbursement_list/?month=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.records = res.data.data;
      } catch (error) {
        console.log(error);
      }
      // iterable this.records get "id_number and name" append to this.idNumberChoiceList
      this.idNumberChoiceList = [];
      this.records.forEach((record) => {
        const { id_number, name } = record;
        // console.log(id_number, name)
        this.idNumberChoiceList.push({name, id_number});
      });
      console.log(this.idNumberChoiceList);
    },
    // 切換本月 (利用今日)
    getCurrentDate() {
      console.log("getCurrentDate");
      this.selectedDate = new Date();
    },
    // 前一月份
    prevMonth() {
    this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
    this.selectedDate = new Date(this.selectedDate);
    },
    // 下一月份
    nextMonth() {
      this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },

    // 匯出核銷報表
    async exportReport() {
      let month = this.selectedDate.toISOString().slice(0, 7);
      let fileUrl = `/api/attendance/generate_reimbursement_report/`;
      if (this.selectedNumbers.length != 0) {
        fileUrl += `?id_numbers=${this.selectedNumbers.join(',')}`
      }
      fileUrl += `?month=${month}`
      const link = document.createElement("a");
      link.setAttribute("href", fileUrl);
      link.setAttribute(
        "download",
        `data.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // 顯示 tooltip 資料
    showTooltip(item, headerValue, indexValue, event) {

      if (headerValue === 'name') {
        this.tooltipContent = this.maskIdNumber(item['id_number']);
      } else if (headerValue === 'staff_name') {
        this.tooltipContent = item['staff_id'];
      }
      console.log("showTooltip", this.tooltipContent)
      this.tooltipVisible = true;
      this.tooltipX = event.clientX + 10; // Add some offset to avoid overlap
      this.tooltipY = event.clientY + 10; // Add some offset to avoid overlap
      this.currentHeader = headerValue;
      this.currentItemIndex = indexValue;
      
    },
    maskIdNumber(id) {
      if (typeof id === 'string' && id.length > 6) {
        return id.slice(0, -6) + '******'
      }
      return id
    },
    hideTooltip() {
      console.log("hideTooltip");
      this.tooltipVisible = false;
      this.tooltipContent = '';
      this.currentHeader = '';
    },
    filterCases() {
      let filteredCases = [];
      if (this.selectedCases.length != 0) {
        const selectedIds = this.selectedCases.map(caseItem => caseItem.id_number);
        this.records = this.records.filter(record => { 
          if(selectedIds.includes(record.id_number)) {
            filteredCases.push(record);
            this.selectedNumbers.push(record.id_number);
          }
        }
        );
      }
      this.records = filteredCases;
    }
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        return `${year}-${month.toString().padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1);
      },
    },
  },
  mounted() {
    // this.getHeaders();
    this.getList();
  },
  watch: {
    selectedDate() {
      this.getList();
    },
    "editedData.editedStartDate": function (newValue, oldValue) {
      console.log(newValue);
      // 檢查newValue是否符合日期格式
      if (newValue.length === 4) {
        this.editedData.editedStartDate = newValue + "-";
      } else if (newValue.length === 7) {
        this.editedData.editedStartDate = newValue + "-";
      }
    },
  }
};
</script>
<style scoped>
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 75vw;
}

.table {
  width: 100%;
  max-height: 100%;
}

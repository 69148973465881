<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary height="100vh" color="#025464" dark app>
      <v-list rounded>
        <v-list-item @click="showLogoutDialog = true">
          <v-list-item-content>
            <v-list-item-title style="filter: grayscale(100%)">
              目前人員：{{ staffName }} | 登出🚪
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <template v-for="item in navItems">
          <v-list-group
            v-if="item.children && item.children.length"
            :key="item.text"
            no-action
            color="#FFFFFF"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-bold">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.link"
              link
              :to="child.link"
              class="px-5"
            >
              <v-list-item-content class="pa-2">
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.link" link :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark color="#025464">
      <v-app-bar-nav-icon
        v-if="!$route.name?.includes('login')"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-bold text-xs-caption text-sm-h6 text-md-h5">
        <v-row no-gutters align="center">
          <div class="align-center mx-4">{{ pageTitle }}</div>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="main-content"> <router-view /></v-main>
    <!-- 登出確認視窗 -->
    <v-dialog v-model="showLogoutDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">登出確認</v-card-title>
        <v-card-text>確認要登出嗎？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showLogoutDialog = false">取消</v-btn>
          <v-btn color="green darken-1" text @click="logout">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer dark padless>
      <v-card flat tile color="#025464" class="white--text text-center" width="100vw">
        <v-card-text class="white--text pa-1">
          <div class="ma-2">
            永扶科技有限公司版權所有 © {{ new Date().getFullYear() }} ,
            <strong class="white--text"> Always Support Technology. All Rights Reserved. </strong>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data: () => ({
    dialog: false,
    drawer: false,
    staffName: '',
    showLogoutDialog: false,
    nav: [],
    icons: [
      { url: 'https://www.asttw.com/', icon: 'mdi-web' },
      {
        url: 'https://www.facebook.com/AlwaysSupportTech',
        icon: 'mdi-facebook',
      },
      {
        url: 'https://www.youtube.com/@AlwaysSupportTech',
        icon: 'mdi-youtube',
      },
    ],
  }),
  methods: {
    toURL(url) {
      window.open(url)
    },
    updateTitle() {
      const currentRoute = this.$route.name
      const matchingNavItem = this.navItems
        .flat()
        .find((item) => item.children.some((child) => child.link === currentRoute))
      if (matchingNavItem) {
        const activeChild = matchingNavItem.children.find((child) => child.link === currentRoute)
        if (activeChild) {
          this.pageTitle = `AST Senior Living Genie - ${activeChild.title}`
        }
      } else {
        this.pageTitle = 'AST Senior Living Genie'
      }
    },
    async getStaffName() {
      const qs = require('qs')
      const staff = sessionStorage.getItem('staff')
      const staff_id = JSON.parse(staff).id
      let config = {
        method: 'get',
        url: `/api/attendance/get_employee_name?employee_id=${staff_id}`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        this.staffName = res.data.data
        console.log(this.staffName)
      } catch (error) {
        console.log(error)
      }
    },
    logout() {
      if (window.location.href.includes('contactbook')) {
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('isUserLogin')
        this.showLogoutDialog = false
        this.$router.push('/contactbook/login')
      } else {
        sessionStorage.removeItem('staff')
        sessionStorage.removeItem('isStaffLogin')
        this.showLogoutDialog = false
        this.$router.push('/login')
      }
    },
  },
  computed: {
    navItems() {
      const routesToHideNav = ['contactbook', 'contactbook-login']
      if (routesToHideNav.includes(this.$route.name)) {
        this.nav = []
      } else {
        this.nav = [
          {
            text: '電子白板',
            link: '/whiteboard',
          },
          {
            text: '個案總覽',
            link: '/case-dashboard',
          },
          {
            text: '個案管理',
            children: [
              {
                title: '個案列表',
                link: '/case-list',
              },
              {
                title: '出勤及生理數據',
                link: '/case-management',
              },
              {
                title: '個案日誌',
                link: '/case-daily-record',
              },
              {
                title: '簽到退紀錄',
                link: '/checkinsheet',
              },
              {
                title: '尿液檢測',
                link: '/urine-management',
              },
              {
                title: '主責管理',
                link: '/care-management',
              },
            ],
          },
          {
            text: '交通管理',
            children: [
              {
                title: '交通車簽到',
                link: '/shuttle-sign',
              },
              {
                title: '趟次規劃',
                link: '/shuttle-scheduler',
              },
              {
                title: '車輛管理',
                link: '/shuttle-list',
              },
            ],
          },
          {
            text: '員工管理',
            children: [
              {
                title: '員工列表',
                link: '/staff-list',
              },
            ],
          },
          {
            text: '報表管理',
            children: [
              {
                title: '核銷報表',
                link: '/reimbursement',
              },
              {
                title: '個案日誌活動紀錄表',
                link: '/service-record',
              },
              {
                title: '生命徵象月報表',
                link: '/vital-sign-record',
              },
            ],
          },
          {
            text: '衛材管理',
            children: [
              {
                title: '衛材登記',
                link: '/material-management',
              },
              {
                title: '每月結算',
                link: '/material-settlement',
              },
            ],
          },
          {
            text: '其他',
            children: [
              {
                title: '差勤系統',
                link: '/employee-attendance',
              },
              {
                title: '旅遊儀錶板',
                link: '/travel-dashboard',
              },
              {
                title: '緊急聯絡單',
                link: '/emergencycontact',
              },
            ],
          },
          {
            text: '品質獎勵計畫表單',
            children: [
              {
                title: '藥物安全性評估',
                link: '/assessment/medication-safety',
              },
              {
                title: '身體評估',
                link: '/assessment/physical-assessment',
              },
              {
                title: '壓力性損傷危險評估',
                link: '/assessment/pressure-injury-risk',
              },
              {
                title: '跌倒危險性評估',
                link: '/assessment/fall-risk',
              },
              {
                title: '日常生活功能評估',
                link: '/assessment/adl-assessment',
              },
              {
                title: '工具性日常生活活動功能評估',
                link: '/assessment/iadl-assessment',
              },
              {
                title: '認知功能評估',
                link: '/assessment/cognitive-assessment',
              },
              {
                title: '簡式健康量表 (BSRS-5)',
                link: '/assessment/bsrs5',
              },
              {
                title: '簡易營養評估',
                link: '/assessment/nutrition-assessment',
              },
              {
                title: '疼痛評估',
                link: '/assessment/pain-assessment',
              },
              {
                title: '衰弱評估',
                link: '/assessment/frailty-assessment',
              },
              {
                title: '照護計畫',
                link: '/assessment/care-plans',
              },
            ],
          },
        ]
      }
      return this.nav
    },
    pageTitle() {
      const isMobile = this.$vuetify.breakpoint.width <= 600
      if (isMobile) {
        return this.$route.meta.title || ''
      } else {
        return this.$route.meta.title
          ? `AST Senior Living Genie - ${this.$route.meta.title}`
          : 'AST Senior Living Genie'
      }
    },
  },
  watch: {
    // $route(to, from) {
    //   this.updateTitle();
    // },
  },
  mounted() {
    // this.getStaffName()
    // this.updateTitle();
  },
}
</script>
<style scoped>
.v-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

::v-deep .v-application--wrap {
  background: #f2f2f2;
}

router-view {
  flex: 1;
}

.main-content {
  padding-bottom: 64px !important;
}

.v-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
</style>

<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="addStaff"> 建立新員工 </v-btn>
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="hover-effect" @click="editStaff(item)">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.gender`]="{ item, index }">
          <span
            v-if="item.gender == null"
            class="text-add hover-add"
            @mouseover="colorItems.gender = '#258525'"
            @mouseout="colorItems.gender = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.gender == '1' ? '男' : '女' }}
          </span>
        </template>
        <template v-slot:[`item.phone`]="{ item, index }">
          <span
            v-if="item.phone == null"
            class="text-add hover-add"
            @mouseover="colorItems.phone = '#258525'"
            @mouseout="colorItems.phone = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.phone }}
          </span>
        </template>
        <template v-slot:[`item.type`]="{ item, index }">
          <span
            v-if="item.type == null"
            class="text-add hover-add"
            @mouseover="colorItems.type = '#258525'"
            @mouseout="colorItems.type = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.type }}
          </span>
        </template>
        <template v-slot:[`item.start_date`]="{ item, index }">
          <span
            v-if="item.start_date == null"
            class="text-add hover-add"
            @mouseover="colorItems.start_date = '#258525'"
            @mouseout="colorItems.start_date = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.start_date }}
          </span>
        </template>
        <!-- <template v-slot:[`item.end_date`]="{ item, index }">
          <span
            v-if="item.end_date == null"
            class="text-add hover-add"
            @mouseover="colorItems.end_date = '#258525'"
            @mouseout="colorItems.end_date = '#8d8d8d'"
            @click=editStaff(item)
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.end_date }}
          </span>
        </template> -->
        <template v-slot:[`item.status`]="{ item, index }">
          <span
            v-if="item.status == null"
            class="text-add hover-add"
            @mouseover="colorItems.status = '#258525'"
            @mouseout="colorItems.status = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.status ? '啟用' : '離職' }}
          </span>
        </template>
        <template v-slot:[`item.driver_license`]="{ item, index }">
          <span
            v-if="item.driver_license == null"
            class="text-add hover-add"
            @mouseover="colorItems.driver_license = '#258525'"
            @mouseout="colorItems.driver_license = '#8d8d8d'"
            @click="editStaff(item)"
          >
            編輯
          </span>
          <span v-else class="hover-effect" @click="editStaff(item)">
            {{ item.driver_license ? '有' : '無' }}
          </span>
        </template>
      </v-data-table>
    </div>

    <v-dialog persistent v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <div v-if="isEdit">員工資料編輯 - {{ editedData.editedName }}</div>
          <div v-else>建立新員工</div>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  label="姓名"
                  v-model="editedData.editedName"
                  hide-details=""
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="身份證字號"
                  v-model="editedData.editedIdNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="生日(yyyy-mm-dd)"
                  v-model="editedData.editedBirth"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="性別"
                  v-model="editedData.editedGender"
                  :items="genderOptions"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="電話"
                  v-model="editedData.editedPhone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="人力類型"
                  v-model="editedData.editedJob"
                  :items="jobOptions"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="到職日(yyyy-mm-dd)"
                  v-model="editedData.editedStartDate"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="離職日"
                  v-model="end_date"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="職業駕照"
                  :items="driverLicenseOptions"
                  v-model="editedData.editedDriverLicense"
                ></v-select> </v-col
            ></v-row>
            <v-divider />
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  label="個案狀態"
                  v-model="editedData.editedStatus"
                  :items="statusOptions"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <!-- <v-btn text color="red" @click="dialog = false">拒測</v-btn> -->
            <v-btn text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
export default {
  data() {
    return {
      headers: [
        {
          text: '姓名',
          value: 'name',
          align: 'center',
          width: '100px',
        },
        { text: '性別', value: 'gender', align: 'center', width: '75px' },
        { text: '電話', value: 'phone', align: 'center', width: '75px' },
        {
          text: '人力類型',
          value: 'type',
          align: 'center',
          width: '75px',
        },
        { text: '到職日', value: 'start_date', align: 'center', width: '75px' },
        // {
        //   text: "離職日",
        //   value: "end_date",
        //   align: "center",
        //   width: "75px",
        // },
        {
          text: '職業駕照',
          value: 'driver_license',
          align: 'center',
          width: '75px',
        },
        { text: '啟用狀態', value: 'status', align: 'center', width: '75px' },
      ],
      records: [
        // {
        //   name: "蘇凱負",
        //   type: "照服員",
        //   start_date: "2024-01-01",
        //   gender: "1",
        //   phone: "0955884753",
        //   driver_license: true,
        //   status: true,
        // },
        // {
        //   name: "吳佳西",
        //   type: "司機",
        //   start_date: "2023-06-04",
        //   gender: "1",
        //   phone: "0987987487",
        //   driver_license: true,
        //   status: true,
        // },
        // {
        //   name: "孫小美",
        //   type: "護理師",
        //   start_date: "2023-06-04",
        //   gender: "2",
        //   phone: "0978678978",
        //   driver_license: false,
        //   status: false,
        // },
      ],
      dialog: false,
      isEdit: false,
      staff_id: '',
      case_id: '',
      index: '',
      caseName: '',
      time: '',
      phone: '',
      end_date: '',
      driver_license: '',
      group_2: '',
      colorItems: {
        type: '#8d8d8d',
        start_date: '#8d8d8d',
        gender: '#8d8d8d',
        phone: '#8d8d8d',
        end_date: '#8d8d8d',
        driver_license: '#8d8d8d',
        status: '#8d8d8d',
        group_1: '#8d8d8d',
        group_2: '#8d8d8d',
      },
      menu: false,
      selectedDate: new Date(),
      jobOptions: [],
      genderOptions: [
        { text: '男', value: 1 },
        { text: '女', value: 2 },
      ],
      driverLicenseOptions: [
        { text: '有', value: true },
        { text: '無', value: false },
      ],
      statusOptions: [
        { text: '啟用', value: true },
        { text: '離職', value: false },
      ],
      editedData: {
        editedEmployeeID: null,
        editedName: null,
        editedIdNumber: null,
        editedBirth: null,
        editedGender: null,
        editedPhone: null,
        editedJob: null,
        editedStartDate: null,
        editedDriverLicense: false,
        editedStatus: false,
      },
    }
  },
  methods: {
    async getList() {
      // console.log(this.formattedDate);
      let config = {
        method: 'get',
        url: `/api/attendance/list_employee`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        console.log(res.data.data)
        this.records = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    initialInputData() {
      for (let key in this.editedData) {
        this.editedData[key] = null
      }
    },
    async getJob() {
      // console.log(this.formattedDate);

      let config = {
        method: 'get',
        url: `/api/attendance/list_job`,
        header: {},
      }
      try {
        let res = await axios.request(config)
        // console.log(res.data.data);
        this.jobOptions = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async submit() {
      let data = qs.stringify({
        name: this.editedData.editedName,
        phone: this.editedData.editedPhone,
        birth: this.editedData.editedBirth,
        gender: this.editedData.editedGender,
        id_num: this.editedData.editedIdNumber,
        hire_date: this.editedData.editedStartDate,
        status: this.editedData.editedStatus,
        drive_license: this.editedData.editedDriverLicense,
        job: this.editedData.editedJob,
      })
      let config = {}
      if (this.isEdit === true) {
        config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/attendance/edit_employee/' + this.editedData.editedEmployeeID + '/',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: data,
        }
      } else {
        config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/attendance/create_employee',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: data,
        }
      }

      try {
        let res = await axios.request(config)
        console.log(res.data)
        this.dialog = false
      } catch (error) {
        console.log(error)
      }
    },
    addStaff() {
      this.isEdit = false
      this.initialInputData()
      this.dialog = true
    },
    editStaff(item) {
      this.isEdit = true
      this.editedData.editedEmployeeID = item.employee_id
      this.editedData.editedName = item.name
      this.editedData.editedPhone = item.phone
      this.editedData.editedBirth = item.birth
      this.editedData.editedGender = item.gender
      this.editedData.editedIdNumber = item.id_number
      this.editedData.editedStartDate = item.start_date
      this.editedData.editedStatus = item.status
      this.editedData.editedDriverLicense = item.driver_license
      this.editedData.editedJob = item.type
      this.dialog = true
    },
  },
  computed: {},
  mounted() {
    this.getList()
    this.getJob()
  },
  watch: {
    'editedData.editedBirth': function (newValue, oldValue) {
      console.log(newValue)
      // 檢查newValue是否符合日期格式
      if (newValue !== null && newValue.length === 4) {
        this.editedData.editedBirth = newValue + '-'
      } else if (newValue !== null && newValue.length === 7) {
        this.editedData.editedBirth = newValue + '-'
      }
    },
    'editedData.editedStartDate': function (newValue, oldValue) {
      console.log(newValue)
      // 檢查newValue是否符合日期格式
      if (newValue !== null && newValue.length === 4) {
        this.editedData.editedStartDate = newValue + '-'
      } else if (newValue !== null && newValue.length === 7) {
        this.editedData.editedStartDate = newValue + '-'
      }
    },
  },
  created() {
    let staff = sessionStorage.getItem('staff')
    this.staff_id = JSON.parse(staff).id
  },
}
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}
</style>

<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <!-- ASSESSMENT_DT -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_DT"
            label="評估日期"
            :rules="[rules.required, rules.dateFormat]"
            maxlength="8"
            placeholder="yyyyMMdd"
            required
          ></v-text-field>
        </v-col>

        <!-- ASSESSMENT_USER -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            outlined
            v-model="form.ASSESSMENT_USER"
            label="評估人員"
            :rules="[rules.required]"
            maxlength="200"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 新增照護計畫按鈕 -->
      <v-row class="pa-2" justify="center">
        <v-btn color="primary" @click="openDialog">新增照護計畫</v-btn>
      </v-row>
    </v-form>

    <!-- 顯示整理好的 result -->
    <v-row>
      <v-col cols="12">
        <div class="text-h6 py-2 font-weight-bold">目前的照護計畫</div>
        <div v-for="(plan, index) in carePlans" :key="index">
          <v-card class="mb-3">
            <v-card-title>
              {{ plan.title }}
              <v-spacer></v-spacer>
              <v-btn icon @click="editPlan(index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="wrap">{{ plan.details }}</v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="1200px">
      <v-card>
        <v-card-title>照護計畫</v-card-title>
        <v-card-text>
          <v-form ref="dialogForm" v-model="isDialogFormValid">
            <v-row>
              <v-col cols="12">
                <v-select
                  outlined
                  v-model="selectedDiagnosis"
                  :items="diagnosisTitles"
                  label="請選擇照護計畫"
                  @change="updateDiagnosis"
                ></v-select>
              </v-col>
            </v-row>

            <div v-if="activeDiagnosis">
              <!-- 開始與結束日期 -->
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    v-model="form.START_DATE"
                    label="進行日期"
                    :rules="[rules.required, rules.dateFormat]"
                    placeholder="yyyyMMdd"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    v-model="form.END_DATE"
                    label="結束日期"
                    :rules="[rules.required, rules.dateFormat]"
                    placeholder="yyyyMMdd"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- 導因 -->
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 py-2 font-weight-bold black--text">導因</div>
                  <v-row>
                    <v-col
                      v-for="cause in activeDiagnosis.sections.causes"
                      :key="cause"
                      cols="12"
                      sm="4"
                    >
                      <v-checkbox
                        dense
                        v-model="form.causes"
                        :label="cause"
                        :value="cause"
                      ></v-checkbox></v-col
                  ></v-row>
                </v-col>
              </v-row>

              <!-- 鑑定特徵 -->
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 py-2 font-weight-bold black--text">鑑定特徵</div>
                  <v-row>
                    <v-col
                      v-for="characteristic in activeDiagnosis.sections.characteristics"
                      :key="characteristic"
                      cols="12"
                      sm="4"
                    >
                      <v-checkbox
                        dense
                        v-model="form.characteristics"
                        :label="characteristic"
                        :value="characteristic"
                      ></v-checkbox></v-col
                  ></v-row>
                </v-col>
              </v-row>

              <!-- 護理目標 -->
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 py-2 font-weight-bold black--text">護理目標</div>
                  <v-row>
                    <v-col
                      v-for="goal in activeDiagnosis.sections.goals"
                      :key="goal"
                      cols="12"
                      sm="4"
                    >
                      <v-checkbox
                        dense
                        v-model="form.goals"
                        :label="goal"
                        :value="goal"
                      ></v-checkbox></v-col
                  ></v-row>
                </v-col>
              </v-row>

              <!-- 護理措施 -->
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 py-2 font-weight-bold black--text">護理措施</div>
                  <v-row>
                    <v-col
                      v-for="(action, index) in activeDiagnosis.sections.actions"
                      :key="index"
                      cols="12"
                      sm="6"
                    >
                      <v-row align="center">
                        <v-col>
                          <v-row dense>
                            <v-checkbox
                              dense
                              v-model="form.actions"
                              :value="action.text"
                              :label="action.text"
                            ></v-checkbox>
                            <div v-if="action.input" class="px-2">
                              <v-row>
                                <v-col v-for="(field, i) in action.inputFields" :key="i">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-model="form.inputValues[action.text][i]"
                                    :label="field"
                                    style="width: 120px"
                                  ></v-text-field></v-col
                              ></v-row></div
                          ></v-row>
                        </v-col>
                      </v-row> </v-col
                  ></v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="closeDialog" class="ma-4">取消</v-btn>
          <v-btn color="primary" @click="submitDialogForm" class="ma-4">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      diagnoses: [],
      diagnosisTitles: [],
      selectedDiagnosis: null,
      activeDiagnosis: null,
      isFormValid: false,
      isDialogFormValid: false,
      dialog: false,
      carePlans: [],
      editingIndex: null,
      form: {
        START_DATE: '',
        END_DATE: '',
        causes: [],
        characteristics: [],
        goals: [],
        actions: [],
        inputValues: {},
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        numeric: (value) => !isNaN(value) || '必須是數字',
        dateFormat: (value) => /\d{8}/.test(value) || '請輸入正確的日期格式 yyyyMMdd',
      },
    }
  },
  methods: {
    openDialog() {
      this.resetForm()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    resetForm() {
      this.form = {
        START_DATE: '',
        END_DATE: '',
        causes: [],
        characteristics: [],
        goals: [],
        actions: [],
        inputValues: {},
      }
      this.selectedDiagnosis = null
      this.activeDiagnosis = null
      this.editingIndex = null
    },
    updateDiagnosis() {
      const selectedId = this.selectedDiagnosis.split(' ')[0].replace('#', '')
      this.activeDiagnosis = this.diagnoses.find((d) => d.id === selectedId)

      this.form.causes = []
      this.form.characteristics = []
      this.form.goals = []
      this.form.actions = []
      this.form.inputValues = {}

      if (this.activeDiagnosis) {
        this.activeDiagnosis.sections.actions.forEach((action) => {
          if (action.input) {
            this.form.inputValues[action.text] = Array(action.inputFields.length).fill('')
          }
        })
      }
    },
    submitDialogForm() {
      const planDetails = {
        title: this.selectedDiagnosis,
        details: this.formatResult(),
      }

      if (this.editingIndex !== null) {
        this.carePlans.splice(this.editingIndex, 1, planDetails)
      } else {
        this.carePlans.push(planDetails)
      }

      this.dialog = false
    },
    editPlan(index) {
      const plan = this.carePlans[index]
      this.selectedDiagnosis = plan.title
      this.updateDiagnosis()
      this.editingIndex = index
      this.dialog = true
    },
    formatResult() {
      let result = `進行日期: ${this.form.START_DATE}\n`
      result += `結束日期: ${this.form.END_DATE}\n`
      result += `導因: ${this.form.causes.join(', ')}\n`
      result += `鑑定特徵: ${this.form.characteristics.join(', ')}\n`
      result += `護理目標: ${this.form.goals.join(', ')}\n`

      const formattedActions = this.activeDiagnosis.sections.actions
        .map((action) => {
          if (this.form.actions.includes(action.text)) {
            if (action.input) {
              const inputValues = this.form.inputValues[action.text] || []
              let formattedAction = action.text
              inputValues.forEach((value, index) => {
                formattedAction += `${value || '___'}${action.inputFields[index]}`
              })
              return formattedAction
            }
            return action.text
          }
          return null
        })
        .filter((action) => action !== null)

      result += `護理措施: ${formattedActions.join(', ')}\n`
      return result
    },
  },
  mounted() {
    try {
      const jsonData = require('@/data/diagnoses.json')
      if (jsonData.diagnoses && Array.isArray(jsonData.diagnoses)) {
        this.diagnoses = jsonData.diagnoses
        this.diagnosisTitles = this.diagnoses.map((d) => `#${d.id} ${d.title}`)
      } else {
        console.error('無法找到 diagnoses ')
      }
    } catch (error) {
      console.error('載入 JSON 檔案時出錯', error)
    }
  },
}
</script>
<style scoped>
.wrap {
  white-space: pre-line;
}
</style>

<template>
  <v-container>
    <v-btn color="#141E46" class="mb-3 white--text" @click="addVehicle">建立新車輛</v-btn>

    <v-data-table
      :headers="headers"
      :items="vehicles"
      :items-per-page="-1"
      class="elevation-1"
      disable-sort
      hide-default-footer
    >
      <template slot="item.actions" slot-scope="{ item }">
        <v-icon class="mr-2" @click="editVehicle(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">編輯車輛</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="editedItem.car_serial" label="車別"></v-text-field>
            <v-select
              v-model="editedItem.institution_code"
              :items="institutions"
              item-text="institution_name"
              item-value="institution_code"
              label="服務單位"
            ></v-select>
            <v-select
              v-model="editedItem.employee_id"
              :items="drivers"
              item-text="name"
              item-value="employee_id"
              label="司機員"
            ></v-select>
            <v-text-field v-model="editedItem.license_plate" label="車牌號碼"></v-text-field>
            <v-text-field
              v-model.number="editedItem.trips"
              label="趟數(接)"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.number="editedItem.return_trips"
              label="趟數(送)"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.number="editedItem.max_capacity"
              label="每趟限載"
              type="number"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">取消</v-btn>
          <v-btn color="green darken-1" text @click="saveVehicle">儲存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        car_serial: '',
        institution_code: '',
        employee_id: '',
        license_plate: '',
        trips: 0,
        return_trips: 0,
        max_capacity: 0,
      },
      vehicles: [],
      institutions: [],
      drivers: [],
      headers: [
        { text: '車別', value: 'car_serial', align: 'center' },
        { text: '服務單位', value: 'institution_code', align: 'center' },
        { text: '司機員', value: 'employee_id', align: 'center' },
        { text: '車牌號碼', value: 'license_plate', align: 'center' },
        { text: '趟數(接)', value: 'trips', align: 'center' },
        { text: '趟數(送)', value: 'return_trips', align: 'center' },
        { text: '每趟限載', value: 'max_capacity', align: 'center' },
        { text: '修改', value: 'actions', align: 'center', sortable: false },
      ],
    }
  },
  mounted() {
    this.fetchVehicles()
    this.fetchInstitutions()
    this.fetchDrivers()
  },
  methods: {
    async fetchVehicles() {
      try {
        const response = await axios.get('/api/traffic/shuttle/list/')
        if (response.data.success === 'success') {
          this.vehicles = response.data.data
        }
      } catch (error) {
        console.error('Error fetching vehicles:', error)
      }
    },
    async fetchInstitutions() {
      try {
        const response = await axios.get('/api/attendance/list_institution')
        if (response.data.status === 'success') {
          this.institutions = response.data.data
        }
      } catch (error) {
        console.error('Error fetching institutions:', error)
      }
    },
    async fetchDrivers() {
      try {
        const response = await axios.get('/api/attendance/list_employee')
        if (response.data.status === 'success') {
          this.drivers = response.data.data.filter((emp) => emp.type === '司機')
        }
      } catch (error) {
        console.error('Error fetching drivers:', error)
      }
    },
    addVehicle() {
      this.editedIndex = -1
      this.editedItem = {
        car_serial: '',
        institution_code: '',
        employee_id: '',
        license_plate: '',
        trips: 0,
        return_trips: 0,
        max_capacity: 0,
      }
      this.dialog = true
    },
    editVehicle(item) {
      this.editedIndex = this.vehicles.findIndex((v) => v.car_serial === item.car_serial)
      this.editedItem = { ...item }
      this.dialog = true
    },
    async saveVehicle() {
      try {
        if (this.editedIndex > -1) {
          await axios.post(
            `/traffic/shuttle/update/${this.editedItem.car_serial}/`,
            this.editedItem
          )
        } else {
          await axios.post('/api/traffic/shuttle/create/', this.editedItem)
        }
        this.closeDialog()
        this.fetchVehicles()
      } catch (error) {
        console.error('Error saving vehicle:', error)
      }
    },
    closeDialog() {
      this.dialog = false
      this.editedIndex = -1
    },
  },
}
</script>

<style scoped>
.v-icon {
  cursor: pointer;
}
</style>

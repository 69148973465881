<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['label', 'dataWeight'],
  data() {
    return {}
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'line', snap: true },
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: this.label,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            name: 'KG',
            type: 'value',
            position: 'left',
            min: 40,
            max: 100,
          },
        ],
        series: [
          {
            name: '體重',
            data: this.dataWeight,
            color: '#FFBA86',
            type: 'line',
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      }
      chart.setOption(option)
    },
  },
  watch: {
    label() {
      this.renderChart()
    },
    dataWeight() {
      this.renderChart()
    },
  },
}
</script>
<style scoped>
.chart {
  width: 600px;
  height: 400px;
}

@media (max-width: 600px) {
  .chart {
    width: 100vw;
    height: 100vw;
  }
}
</style>

<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <!-- 主檔案欄位 -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="主檔案ID"
            v-model="form.MAIN_ID"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="評估日期"
            v-model="form.ASSESSMENT_DT"
            :rules="[rules.required, rules.dateFormat]"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            readonly
            label="評估人員"
            v-model="form.ASSESSMENT_USER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="pa-0" cols="12"> <v-divider /></v-col>

        <!-- 意識項目睜眼 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="意識項目睜眼"
            v-model="form.C_OPEN_EYE"
            :items="options.C_OPEN_EYE"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 意識項目語言 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="意識項目語言"
            v-model="form.C_LANGUAGE"
            :items="options.C_LANGUAGE"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 意識運動 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="意識運動"
            v-model="form.C_SPORT"
            :items="options.C_SPORT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 視力選項 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="視力選項"
            v-model="form.V_OPT"
            :items="options.V_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 視力障礙左 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="視力障礙左"
            v-model="form.V_OBS_Y_L"
            :items="options.V_OBS_Y_L"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 視力障礙右 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="視力障礙右"
            v-model="form.V_OBS_Y_R"
            :items="options.V_OBS_Y_R"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 是否影響日常活動 -->
        <v-col cols="12" sm="6" md="4">
          <v-switch label="視力是否影響日常活動" v-model="form.V_IS_ACT"></v-switch>
        </v-col>

        <!-- 視力輔具 -->
        <v-col cols="12" sm="6" md="4">
          <v-switch label="是否使用視力輔具" v-model="form.V_HAS_AUX"></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            label="視力輔具選項"
            v-model="form.V_HAS_AUX_Y"
            :items="options.V_HAS_AUX_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.requiredIf(form.V_HAS_AUX)]"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="視力輔具其他"
            v-model="form.V_HAS_AUX_Y_OTHER"
            :rules="[rules.requiredIf(form.V_HAS_AUX)]"
            outlined
          ></v-text-field>
        </v-col>
        <!-- 聽力選項 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="聽力選項"
            v-model="form.H_OPT"
            :items="options.H_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 聽力障礙左 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="聽力障礙左"
            v-model="form.H_OBS_Y_L"
            :items="options.H_OBS_Y_L"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 聽力障礙右 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="聽力障礙右"
            v-model="form.H_OBS_Y_R"
            :items="options.H_OBS_Y_R"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 聽力影響日常活動 -->
        <v-col cols="12" sm="6" md="4">
          <v-switch label="聽力影響日常活動" v-model="form.H_IS_ACT"></v-switch>
        </v-col>

        <!-- 聽力輔具 -->
        <v-col cols="12" sm="6" md="4">
          <v-switch label="聽力輔具" v-model="form.H_HAS_AUX"></v-switch>
        </v-col>

        <!-- 聽力輔具選項 -->
        <v-col cols="12" sm="6" md="4" v-if="form.H_HAS_AUX">
          <v-select
            label="聽力輔具選項"
            v-model="form.H_HAS_AUX_Y"
            :items="options.H_HAS_AUX_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 聽力輔具其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.H_HAS_AUX">
          <v-text-field
            label="聽力輔具其他"
            v-model="form.H_HAS_AUX_Y_OTHER"
            :rules="[rules.requiredIf(form.H_HAS_AUX)]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 溝通項目 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="溝通項目"
            v-model="form.COM_OPT"
            :items="options.COM_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 溝通影響日常活動 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="溝通影響日常活動"
            v-model="form.COM_IS_ACT"
            :items="options.COM_IS_ACT"
            item-text="name"
            item-value="code"
            :rules="[rules.requiredIf(form.COM_OPT.includes('10'))]"
            outlined
          ></v-select>
        </v-col>

        <!-- 溝通說話 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="溝通說話"
            v-model="form.COM_TALK"
            :items="options.COM_TALK"
            item-text="name"
            item-value="code"
            :rules="[rules.requiredIf(form.COM_OPT.includes('10'))]"
            outlined
          ></v-select>
        </v-col>

        <!-- 溝通理解 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="溝通理解"
            v-model="form.COM_UNDERSTAND"
            :items="options.COM_UNDERSTAND"
            item-text="name"
            item-value="code"
            :rules="[rules.requiredIf(form.COM_OPT.includes('10'))]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔外觀 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔外觀"
            v-model="form.O_LOOK"
            :items="options.O_LOOK"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔外觀項目 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔外觀項目"
            v-model="form.O_LOOK_ABNORMAL"
            :items="options.O_LOOK_ABNORMAL"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔外觀其他 -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="口腔外觀其他"
            v-model="form.O_LOOK_ABNORMAL_OTHER"
            :rules="[rules.requiredIf(form.O_LOOK_ABNORMAL.includes('99'))]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 口腔特殊進食 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔特殊進食"
            v-model="form.O_HAS_EAT"
            :items="options.O_HAS_EAT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔特殊進食項目 -->
        <v-col cols="12" sm="6" md="4" v-if="form.O_HAS_EAT === '1'">
          <v-select
            label="口腔特殊進食項目"
            v-model="form.O_HAS_EAT_Y"
            :items="options.O_HAS_EAT_Y"
            item-text="name"
            item-value="code"
            :rules="[rules.requiredIf(form.O_HAS_EAT.includes('1'))]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔特殊進食項目特殊種類 -->
        <v-col cols="12" sm="6" md="4" v-if="form.O_HAS_EAT_Y === '99'">
          <v-text-field
            label="口腔特殊進食項目特殊種類"
            v-model="form.O_HAS_EAT_Y_OTHER"
            :rules="[rules.requiredIf(form.O_HAS_EAT.includes('99'))]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 口腔假牙狀況 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔假牙狀況"
            v-model="form.O_DENTURE"
            :items="options.O_DENTURE"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 有活動假牙選項 -->
        <v-col cols="12" sm="6" md="4" v-if="form.O_DENTURE === '15'">
          <v-select
            label="有活動假牙選項"
            v-model="form.O_DENTURE_15"
            :items="options.O_DENTURE_15"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.requiredIf(form.O_DENTURE === '15')]"
            outlined
          ></v-select>
        </v-col>

        <!-- 有活動假牙其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.O_DENTURE_15 === '99'">
          <v-text-field
            label="有活動假牙選項其他"
            v-model="form.O_DENTURE_15_OTHER"
            :rules="[rules.requiredIf(form.O_DENTURE_15.includes('99'))]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 口腔清潔工具 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔備有個人口腔清潔工具"
            v-model="form.O_HAS_TOOL"
            :items="options.O_HAS_TOOL"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 有自然牙固定活動假牙項目 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="有自然牙、固定或活動假牙者項目"
            v-model="form.O_CLEAN_TOOL1"
            :items="options.O_CLEAN_TOOL1"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 配戴活動假牙者項目 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="配戴活動假牙者項目"
            v-model="form.O_CLEAN_TOOL2"
            :items="options.O_CLEAN_TOOL2"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 全口無牙且無活動假牙者項目 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="全口無牙且無活動假牙者項目"
            v-model="form.O_CLEAN_TOOL3"
            :items="options.O_CLEAN_TOOL3"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 口腔保健情形 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="口腔保健情形"
            v-model="form.O_SITUATION"
            :items="options.O_SITUATION"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 胃腸腹部狀態 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="胃腸腹部狀態"
            v-model="form.S_ABDOMEN"
            :items="options.S_ABDOMEN"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 胃腸腹部狀態其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.S_ABDOMEN === '99'">
          <v-text-field
            label="胃腸腹部狀態其他"
            v-model="form.S_ABDOMEN_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 胃腸腸蠕動 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="胃腸腸蠕動"
            v-model="form.S_SQUIRM"
            :items="options.S_SQUIRM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 胃腸腸蠕動其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.S_SQUIRM === '99'">
          <v-text-field
            label="胃腸腸蠕動其他"
            v-model="form.S_SQUIRM_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 胃腸消化狀態 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="胃腸消化狀態"
            v-model="form.S_DIGESTION"
            :items="options.S_DIGESTION"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 胃腸消化狀態其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.S_DIGESTION === '99'">
          <v-text-field
            label="胃腸消化狀態其他"
            v-model="form.S_DIGESTION_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排便型態 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排便型態"
            v-model="form.ED_FORM"
            :items="options.ED_FORM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排便型態其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.ED_FORM === '99'">
          <v-text-field
            label="排便型態其他"
            v-model="form.ED_FORM_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排便顏色 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排便顏色"
            v-model="form.ED_COLOR"
            :items="options.ED_COLOR"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排便顏色其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.ED_COLOR === '99'">
          <v-text-field
            label="排便顏色其他"
            v-model="form.ED_COLOR_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排便輔助 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排便輔助"
            v-model="form.ED_AUX"
            :items="options.ED_AUX"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 有排便輔助 -->
        <v-col cols="12" sm="6" md="4" v-if="form.ED_AUX === '1'">
          <v-select
            label="有排便輔助"
            v-model="form.ED_AUX_Y"
            :items="options.ED_AUX_Y"
            item-text="name"
            item-value="code"
            :rules="[rules.requiredIf(form.ED_AUX === '1')]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排便輔助其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.ED_AUX_Y === '99'">
          <v-text-field
            label="排便輔助其他"
            v-model="form.ED_AUX_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排尿型態 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排尿型態"
            v-model="form.EU_FORM"
            :items="options.EU_FORM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排尿型態異常 -->
        <v-col cols="12" sm="6" md="4" v-if="form.EU_FORM === '25'">
          <v-select
            label="排尿型態異常"
            v-model="form.EU_FORM_25"
            :items="options.EU_FORM_25"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排尿型態其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.EU_FORM_25 === '99'">
          <v-text-field
            label="排尿型態其他"
            v-model="form.EU_FORM_25_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排尿顏色 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排尿顏色"
            v-model="form.EU_COLOR"
            :items="options.EU_COLOR"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排尿顏色其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.EU_COLOR === '99'">
          <v-text-field
            label="排尿顏色其他"
            v-model="form.EU_COLOR_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 排尿輔助 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="排尿輔助"
            v-model="form.EU_AUX"
            :items="options.EU_AUX"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 有排尿輔助 -->
        <v-col cols="12" sm="6" md="4" v-if="form.EU_AUX === '15'">
          <v-select
            label="有排尿輔助"
            v-model="form.EU_AUX_Y"
            :items="options.EU_AUX_Y"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 排尿輔助其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.EU_AUX_Y === '99'">
          <v-text-field
            label="排尿輔助其他"
            v-model="form.EU_AUX_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 皮膚溫度 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="皮膚溫度"
            v-model="form.SKIN_WARM"
            :items="options.SKIN_WARM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 皮膚溫度其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.SKIN_WARM === '99'">
          <v-text-field
            label="皮膚溫度其他"
            v-model="form.SKIN_WARM_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 皮膚濕度 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="皮膚濕度"
            v-model="form.SKIN_WET"
            :items="options.SKIN_WET"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 皮膚濕度其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.SKIN_WET === '99'">
          <v-text-field
            label="皮膚濕度其他"
            v-model="form.SKIN_WET_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 皮膚顏色 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="皮膚顏色"
            v-model="form.SKIN_COLOR"
            :items="options.SKIN_COLOR"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 皮膚顏色其他 -->
        <v-col cols="12" sm="6" md="4" v-if="form.SKIN_COLOR === '99'">
          <v-text-field
            label="皮膚顏色其他"
            v-model="form.SKIN_COLOR_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 皮膚水腫 -->
        <v-col cols="12" sm="6" md="4">
          <v-switch label="皮膚水腫" v-model="form.SKIN_HAS_EDEMA"></v-switch>
        </v-col>

        <!-- 皮膚水腫部位 -->
        <v-col cols="12" sm="6" md="4" v-if="form.SKIN_HAS_EDEMA">
          <v-text-field
            label="皮膚水腫部位"
            v-model="form.SKIN_HAS_EDEMA_SCORE"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 皮膚水腫級數 -->
        <v-col cols="12" sm="6" md="4" v-if="form.SKIN_HAS_EDEMA">
          <v-select
            label="皮膚水腫級數"
            v-model="form.SKIN_HAS_EDEMA_LEVEL"
            :items="options.SKIN_HAS_EDEMA_LEVEL"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 皮膚完整 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="皮膚完整"
            v-model="form.SKIN_FULL"
            :items="options.SKIN_FULL"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 肌力上下肢 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力左上肢"
            v-model="form.M_UPPER_LEFT"
            :items="options.M_UPPER"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力右上肢"
            v-model="form.M_UPPER_RIGHT"
            :items="options.M_UPPER"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力左下肢"
            v-model="form.M_BOTTOM_LEFT"
            :items="options.M_BOTTOM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力右下肢"
            v-model="form.M_BOTTOM_RIGHT"
            :items="options.M_BOTTOM"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 肌力行動能力問題 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力行動能力問題"
            v-model="form.M_MOVE"
            :items="options.M_MOVE"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 肌力輔具 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="肌力輔具"
            v-model="form.M_HAS_AUX"
            :items="options.M_HAS_AUX"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.M_HAS_AUX === '1'">
          <v-select
            label="肌力輔具項目"
            v-model="form.M_HAS_AUX_Y"
            :items="options.M_HAS_AUX_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.M_HAS_AUX_Y.includes('99')">
          <v-text-field
            label="肌力輔具項目其他"
            v-model="form.M_HAS_AUX_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 跌倒 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="半年內是否有跌倒經驗?"
            v-model="form.F_OPT"
            :items="options.F_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.F_OPT === '10'">
          <v-text-field
            label="跌倒次數"
            v-model="form.F_OPT_10_TIMES"
            :rules="[rules.required]"
            outlined
            type="number"
          ></v-text-field>
        </v-col>

        <!-- 跌倒導致傷害 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="跌倒有無導致傷害"
            v-model="form.F_HAS_HURT"
            :items="options.F_HAS_HURT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.F_HAS_HURT === '1'">
          <v-select
            label="跌倒導致傷害項目"
            v-model="form.F_HAS_HURT_Y"
            :items="options.F_HAS_HURT_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.F_HAS_HURT_Y.includes('99')">
          <v-text-field
            label="跌倒導致傷害其他"
            v-model="form.F_HAS_HURT_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 行為 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="行為"
            v-model="form.B_OPT"
            :items="options.B_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.B_OPT === '10'">
          <v-select
            label="行為項目"
            v-model="form.B_OPT_Y"
            :items="options.B_OPT_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.B_OPT_Y.includes('99')">
          <v-text-field
            label="行為項目其他"
            v-model="form.B_OPT_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 睡眠 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="睡眠"
            v-model="form.SLEEP_OPT"
            :items="options.SLEEP_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.SLEEP_OPT === '15'">
          <v-select
            label="睡眠障礙項目"
            v-model="form.SLEEP_OPT_Y"
            :items="options.SLEEP_OPT_Y"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.SLEEP_OPT_Y.includes('99')">
          <v-text-field
            label="睡眠障礙項目其他"
            v-model="form.SLEEP_OPT_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.SLEEP_OPT === '15'">
          <v-switch label="是否服用藥物" v-model="form.SLEEP_HAS_MED"></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.SLEEP_HAS_MED">
          <v-select
            label="服用藥物類別"
            v-model="form.SLEEP_MED_TYPE"
            :items="options.SLEEP_MED_TYPE"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="form.SLEEP_HAS_MED">
          <v-select
            label="服用頻率"
            v-model="form.SLEEP_FREQ"
            :items="options.SLEEP_FREQ"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 呼吸輔助器 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="呼吸輔助器"
            v-model="form.BREATHE_HAS_AUX"
            :items="options.BREATHE_HAS_AUX"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.BREATHE_HAS_AUX === '1'">
          <v-select
            label="呼吸輔助器項目"
            v-model="form.BREATHE_HAS_AUX_Y"
            :items="options.BREATHE_HAS_AUX_Y"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <!-- 氧氣機項目 -->
        <v-col cols="12" sm="6" md="4" v-if="form.BREATHE_HAS_AUX_Y === '5'">
          <v-select
            label="氧氣機項目"
            v-model="form.BREATHE_HAS_AUX_Y_OX"
            :items="options.BREATHE_HAS_AUX_Y_OX"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.BREATHE_HAS_AUX_Y_OX.includes('5')">
          <v-text-field
            label="鼻導管"
            v-model="form.BREATHE_HAS_AUX_Y_OX_NOSE"
            type="number"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.BREATHE_HAS_AUX_Y_OX.includes('10')">
          <v-text-field
            label="氧氣面罩"
            v-model="form.BREATHE_HAS_AUX_Y_OX_MASK"
            type="number"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.BREATHE_HAS_AUX_Y === '99'">
          <v-text-field
            label="呼吸輔助器其他"
            v-model="form.BREATHE_HAS_AUX_Y_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>

        <!-- 疫苗 -->
        <v-col cols="12" sm="6" md="4">
          <v-select
            label="疫苗"
            v-model="form.VACCINE_OPT"
            :items="options.VACCINE_OPT"
            item-text="name"
            item-value="code"
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_OPT === '15'">
          <v-select
            label="疫苗項目"
            v-model="form.VACCINE_TYPE"
            :items="options.VACCINE_TYPE"
            item-text="name"
            item-value="code"
            multiple
            :rules="[rules.required]"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_TYPE.includes('5')">
          <v-text-field
            label="流感疫苗施打年份"
            v-model="form.VACCINE_OPT_Y_5_YEAR"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
          <v-switch label="流感疫苗施打年份不記得" v-model="form.VACCINE_OPT_Y_5_REM"></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_TYPE.includes('10')">
          <v-text-field
            label="肺炎雙球菌疫苗施打年份"
            v-model="form.VACCINE_OPT_Y_10_YEAR"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
          <v-switch
            label="肺炎雙球菌疫苗施打年份不記得"
            v-model="form.VACCINE_OPT_Y_10_REM"
          ></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_TYPE.includes('15')">
          <v-text-field
            label="COVID-19 疫苗施打年份"
            v-model="form.VACCINE_OPT_Y_15_YEAR"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
          <v-switch
            label="COVID-19 疫苗施打年份不記得"
            v-model="form.VACCINE_OPT_Y_15_REM"
          ></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_TYPE.includes('99')">
          <v-text-field
            label="其他疫苗施打年份"
            v-model="form.VACCINE_OPT_Y_99_YEAR"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
          <v-switch label="其他疫苗施打年份不記得" v-model="form.VACCINE_OPT_Y_99_REM"></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="form.VACCINE_TYPE.includes('99')">
          <v-text-field
            label="疫苗其他"
            v-model="form.VACCINE_OPT_OTHER"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="pa-2" justify="center"
      ><v-btn color="primary" @click="submitForm"> 送出表單 </v-btn></v-row
    >
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      valid: false,
      form: {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        C_OPEN_EYE: '',
        C_LANGUAGE: [],
        C_SPORT: '',
        V_OPT: '',
        V_OBS_Y_L: [],
        V_OBS_Y_R: [],
        V_IS_ACT: false,
        V_HAS_AUX: false,
        V_HAS_AUX_Y: [],
        V_HAS_AUX_Y_OTHER: '',
        H_OPT: '',
        H_OBS_Y_L: [],
        H_OBS_Y_R: [],
        H_IS_ACT: false,
        H_HAS_AUX: false,
        H_HAS_AUX_Y: [],
        H_HAS_AUX_Y_OTHER: '',
        COM_OPT: '',
        COM_IS_ACT: '',
        COM_TALK: '',
        COM_UNDERSTAND: '',
        O_LOOK: '',
        O_LOOK_ABNORMAL: [],
        O_LOOK_ABNORMAL_OTHER: '',
        O_HAS_EAT: '',
        O_HAS_EAT_Y_OTHER: '',
        O_DENTURE: '',
        O_DENTURE_15: [],
        O_DENTURE_15_OTHER: '',
        O_HAS_TOOL: '',
        O_CLEAN_TOOL1: [],
        O_CLEAN_TOOL2: [],
        O_CLEAN_TOOL3: [],
        O_SITUATION: '',
        S_ABDOMEN: '',
        S_ABDOMEN_OTHER: '',
        S_SQUIRM: '',
        S_SQUIRM_OTHER: '',
        S_DIGESTION: '',
        S_DIGESTION_OTHER: '',
        ED_FORM: '',
        ED_FORM_OTHER: '',
        ED_COLOR: '',
        ED_COLOR_OTHER: '',
        ED_AUX: '',
        ED_AUX_Y: '',
        ED_AUX_Y_OTHER: '',
        EU_FORM: '',
        EU_FORM_25: '',
        EU_FORM_25_OTHER: '',
        EU_COLOR: '',
        EU_COLOR_OTHER: '',
        EU_AUX: '',
        EU_AUX_Y: '',
        EU_AUX_Y_OTHER: '',
        SKIN_WARM: '',
        SKIN_WARM_OTHER: '',
        SKIN_WET: '',
        SKIN_WET_OTHER: '',
        SKIN_COLOR: '',
        SKIN_COLOR_OTHER: '',
        SKIN_HAS_EDEMA: false,
        SKIN_HAS_EDEMA_SCORE: '',
        SKIN_HAS_EDEMA_LEVEL: '',
        SKIN_FULL: '',
        M_UPPER_LEFT: '',
        M_UPPER_RIGHT: '',
        M_BOTTOM_LEFT: '',
        M_BOTTOM_RIGHT: '',
        M_MOVE: '',
        M_HAS_AUX: '',
        M_HAS_AUX_Y: [],
        M_HAS_AUX_Y_OTHER: '',
        F_OPT: '',
        F_OPT_10_TIMES: '',
        F_HAS_HURT: '',
        F_HAS_HURT_Y: [],
        F_HAS_HURT_Y_OTHER: '',
        B_OPT: '',
        B_OPT_Y: [],
        B_OPT_Y_OTHER: '',
        SLEEP_OPT: '',
        SLEEP_OPT_Y: [],
        SLEEP_OPT_Y_OTHER: '',
        SLEEP_HAS_MED: false,
        SLEEP_MED_TYPE: [],
        SLEEP_FREQ: '',
        BREATHE_HAS_AUX: '',
        BREATHE_HAS_AUX_Y: '',
        BREATHE_HAS_AUX_Y_OX: [],
        BREATHE_HAS_AUX_Y_OX_NOSE: '',
        BREATHE_HAS_AUX_Y_OX_MASK: '',
        BREATHE_HAS_AUX_Y_OTHER: '',
        VACCINE_OPT: '',
        VACCINE_TYPE: [],
        VACCINE_OPT_Y_5_YEAR: '',
        VACCINE_OPT_Y_10_YEAR: '',
        VACCINE_OPT_Y_15_YEAR: '',
        VACCINE_OPT_Y_99_YEAR: '',
        VACCINE_OPT_Y_5_REM: false,
        VACCINE_OPT_Y_10_REM: false,
        VACCINE_OPT_Y_15_REM: false,
        VACCINE_OPT_Y_99_REM: false,
        VACCINE_OPT_OTHER: '',
      },
      options: {
        C_OPEN_EYE: [
          { code: '5', name: '4' },
          { code: '10', name: '3' },
          { code: '15', name: '2' },
          { code: '20', name: '1' },
          { code: '25', name: '閉眼' },
        ],
        C_LANGUAGE: [
          { code: '5', name: '5' },
          { code: '10', name: '4' },
          { code: '15', name: '3' },
          { code: '20', name: '2' },
          { code: '25', name: '1' },
          { code: '30', name: '失語症' },
          { code: '35', name: '失聰' },
          { code: '40', name: '插氣管內管' },
          { code: '45', name: '氣切' },
        ],
        C_SPORT: [
          { code: '5', name: '6' },
          { code: '10', name: '5' },
          { code: '15', name: '4' },
          { code: '20', name: '3' },
          { code: '25', name: '2' },
          { code: '30', name: '1' },
        ],
        V_OPT: [
          { code: '5', name: '無障礙' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有障礙' },
        ],
        V_OBS_Y_L: [
          { code: '5', name: '白內障' },
          { code: '10', name: '青光眼' },
          { code: '15', name: '老花眼' },
          { code: '20', name: '近視' },
          { code: '99', name: '其他' },
        ],
        V_OBS_Y_R: [
          { code: '5', name: '白內障' },
          { code: '10', name: '青光眼' },
          { code: '15', name: '老花眼' },
          { code: '20', name: '近視' },
          { code: '99', name: '其他' },
        ],
        V_HAS_AUX_Y: [
          { code: '5', name: '眼鏡' },
          { code: '10', name: '放大鏡' },
          { code: '15', name: '義眼(左)' },
          { code: '20', name: '義眼(右)' },
          { code: '99', name: '其他' },
        ],
        H_OPT: [
          { code: '5', name: '無障礙' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有障礙' },
        ],
        H_OBS_Y_L: [
          { code: '5', name: '重聽' },
          { code: '10', name: '失聽' },
        ],
        H_OBS_Y_R: [
          { code: '5', name: '重聽' },
          { code: '10', name: '失聽' },
        ],
        H_HAS_AUX_Y: [
          { code: '5', name: '助聽器（左耳）' },
          { code: '10', name: '助聽器（右耳）' },
          { code: '99', name: '助聽器（其他）' },
        ],
        COM_OPT: [
          { code: '5', name: '無障礙' },
          { code: '10', name: '有障礙' },
        ],
        COM_IS_ACT: [
          { code: '5', name: '新問題（4週內）' },
          { code: '10', name: '舊問題（4週以上）' },
        ],
        COM_TALK: [
          { code: '5', name: '良好' },
          { code: '10', name: '不清楚' },
          { code: '15', name: '僅可簡單表達句子(詞)' },
          { code: '20', name: '僅可表達零碎的字' },
          { code: '25', name: '無法言語' },
        ],
        COM_UNDERSTAND: [
          { code: '5', name: '良好' },
          { code: '10', name: '僅可理解簡單句子(詞)' },
          { code: '15', name: '儘可以理解關鍵字' },
          { code: '20', name: '無法理解' },
        ],
        O_LOOK: [
          { code: '5', name: '正常' },
          { code: '10', name: '異常' },
        ],
        O_LOOK_ABNORMAL: [
          { code: '5', name: '蒼白' },
          { code: '10', name: '紅腫' },
          { code: '15', name: '黏膜潰瘍或出血' },
          { code: '20', name: '潰傷' },
          { code: '99', name: '其他' },
        ],
        O_HAS_EAT: [
          { code: '0', name: '無' },
          { code: '1', name: '有' },
        ],
        O_HAS_EAT_Y: [
          { code: '5', name: '鼻胃管' },
          { code: '10', name: '胃造廔' },
          { code: '99', name: '特殊種類' },
        ],
        O_DENTURE: [
          { code: '5', name: '無' },
          { code: '10', name: '全口多處無牙，但未裝假牙' },
          { code: '15', name: '有活動假牙' },
          { code: '20', name: '有固定式假牙' },
        ],
        O_DENTURE_15: [
          { code: '5', name: '上顎' },
          { code: '10', name: '下顎' },
          { code: '15', name: '全口' },
          { code: '99', name: '其他' },
        ],
        O_HAS_TOOL: [
          { code: '0', name: '無' },
          { code: '1', name: '有' },
        ],
        O_CLEAN_TOOL1: [
          { code: '5', name: '牙刷' },
          { code: '10', name: '1000ppm含氟牙膏' },
          { code: '15', name: '牙線/牙線棒/牙間刷' },
          { code: '20', name: '漱口杯' },
        ],
        O_CLEAN_TOOL2: [
          { code: '5', name: '假牙專用軟毛牙刷' },
          { code: '10', name: '假牙清潔錠' },
        ],
        O_CLEAN_TOOL3: [
          { code: '5', name: '海棉棒' },
          { code: '10', name: '紗布' },
        ],
        O_SITUATION: [
          { code: '5', name: '至少2次' },
          { code: '10', name: '少於2次' },
        ],
        S_ABDOMEN: [
          { code: '5', name: '正常' },
          { code: '10', name: '脹' },
          { code: '15', name: '硬' },
          { code: '20', name: '腫塊' },
          { code: '25', name: '腹水' },
          { code: '30', name: '壓痛' },
          { code: '35', name: '反彈痛' },
          { code: '99', name: '其他' },
        ],
        S_SQUIRM: [
          { code: '5', name: '正常' },
          { code: '10', name: '快' },
          { code: '15', name: '慢' },
          { code: '99', name: '其他' },
        ],
        S_DIGESTION: [
          { code: '5', name: '正常' },
          { code: '10', name: '不好' },
          { code: '15', name: '非常不好' },
          { code: '99', name: '其他' },
        ],
        ED_FORM: [
          { code: '5', name: '正常(至少每三天一次)' },
          { code: '10', name: '不知道' },
          { code: '15', name: '便秘' },
          { code: '20', name: '腹瀉' },
          { code: '25', name: '失禁' },
          { code: '99', name: '其他' },
        ],
        ED_COLOR: [
          { code: '5', name: '黃褐' },
          { code: '10', name: '墨綠' },
          { code: '15', name: '黑便' },
          { code: '20', name: '不知道' },
          { code: '99', name: '其他' },
        ],
        ED_AUX: [
          { code: '5', name: '無' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有' },
        ],
        ED_AUX_Y: [
          { code: '5', name: '腸造瘻' },
          { code: '99', name: '其他' },
        ],
        EU_FORM: [
          { code: '5', name: '正常' },
          { code: '10', name: '多' },
          { code: '15', name: '少' },
          { code: '20', name: '不知道' },
          { code: '25', name: '異常' },
        ],
        EU_FORM_25: [
          { code: '5', name: '失禁' },
          { code: '10', name: '無尿' },
          { code: '15', name: '頻尿' },
          { code: '20', name: '排尿困難' },
          { code: '25', name: '血尿透析' },
          { code: '30', name: '腹膜透析' },
          { code: '99', name: '其他' },
        ],
        EU_COLOR: [
          { code: '5', name: '正常' },
          { code: '10', name: '不知道' },
          { code: '15', name: '異常' },
        ],
        EU_AUX: [
          { code: '5', name: '無' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有' },
        ],
        EU_AUX_Y: [
          { code: '5', name: '尿布' },
          { code: '10', name: '間歇導尿' },
          { code: '15', name: '導尿管' },
          { code: '20', name: '膀胱造瘻' },
          { code: '99', name: '其他' },
        ],
        SKIN_WARM: [
          { code: '5', name: '溫暖' },
          { code: '10', name: '冰冷' },
          { code: '99', name: '其他' },
        ],
        SKIN_WET: [
          { code: '5', name: '無異常' },
          { code: '10', name: '過度乾燥' },
          { code: '15', name: '濕汗' },
          { code: '99', name: '其他' },
        ],
        SKIN_COLOR: [
          { code: '5', name: '無異常' },
          { code: '10', name: '蒼白' },
          { code: '15', name: '潮紅' },
          { code: '20', name: '紫紺' },
          { code: '25', name: '黃疸' },
          { code: '99', name: '其他' },
        ],
        SKIN_HAS_EDEMA_LEVEL: [
          { code: '5', name: '輕' },
          { code: '10', name: '中' },
          { code: '15', name: '重' },
          { code: '20', name: '極重' },
        ],
        SKIN_FULL: [
          { code: '0', name: '否' },
          { code: '1', name: '是' },
        ],
        M_UPPER: [
          { code: '5', name: '5' },
          { code: '10', name: '4' },
          { code: '15', name: '3' },
          { code: '20', name: '2' },
          { code: '25', name: '1' },
          { code: '30', name: '0' },
          { code: '35', name: '無法測量' },
        ],
        M_BOTTOM: [
          { code: '5', name: '5' },
          { code: '10', name: '4' },
          { code: '15', name: '3' },
          { code: '20', name: '2' },
          { code: '25', name: '1' },
          { code: '30', name: '0' },
          { code: '35', name: '無法測量' },
        ],
        M_MOVE: [
          { code: '5', name: '可獨立於戶外行走' },
          { code: '10', name: '可戶外行走但需人陪伴' },
          { code: '15', name: '只能於室內獨立行走' },
          { code: '20', name: '只能於室內行走且需人陪伴' },
          { code: '25', name: '無法行走' },
        ],
        M_HAS_AUX: [
          { code: '0', name: '無' },
          { code: '1', name: '有' },
        ],
        M_HAS_AUX_Y: [
          { code: '5', name: '義肢左手' },
          { code: '10', name: '義肢右手' },
          { code: '15', name: '義肢左腳' },
          { code: '20', name: '義肢右腳' },
          { code: '25', name: '副木左手' },
          { code: '30', name: '副木右手' },
          { code: '35', name: '副木左腳' },
          { code: '40', name: '副木右腳' },
          { code: '45', name: '助行器' },
          { code: '50', name: '單拐' },
          { code: '55', name: '三/四腳拐' },
          { code: '60', name: '輪椅' },
          { code: '65', name: '電動車' },
          { code: '70', name: '氣墊床' },
          { code: '99', name: '其他' },
        ],
        F_OPT: [
          { code: '5', name: '無' },
          { code: '10', name: '有' },
          { code: '15', name: '不知道' },
        ],
        F_HAS_HURT: [
          { code: '0', name: '無' },
          { code: '1', name: '有' },
        ],
        F_HAS_HURT_Y: [
          { code: '5', name: '瘀青' },
          { code: '10', name: '皮肉傷口' },
          { code: '15', name: '頭部外傷' },
          { code: '20', name: '顱內出血' },
          { code: '25', name: '腕部骨折' },
          { code: '30', name: '脊椎骨折' },
          { code: '35', name: '髖部骨折' },
          { code: '99', name: '其他' },
        ],
        B_OPT: [
          { code: '5', name: '無干擾行為' },
          { code: '10', name: '有干擾行為' },
        ],
        B_OPT_Y: [
          { code: '5', name: '攻擊行為' },
          { code: '10', name: '自傷' },
          { code: '15', name: '遊走' },
          { code: '99', name: '其他' },
        ],
        SLEEP_OPT: [
          { code: '5', name: '無障礙' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有障礙' },
        ],
        SLEEP_OPT_Y: [
          { code: '5', name: '不易入睡' },
          { code: '10', name: '早醒' },
          { code: '15', name: '斷續睡眠' },
          { code: '20', name: '日夜顛倒' },
          { code: '99', name: '其他' },
        ],
        SLEEP_MED_TYPE: [
          { code: '5', name: '抗焦慮劑' },
          { code: '10', name: '鎮靜劑' },
          { code: '15', name: '抗憂鬱劑' },
          { code: '20', name: '抗精神病劑' },
          { code: '99', name: '其他' },
        ],
        SLEEP_FREQ: [
          { code: '5', name: '偶爾' },
          { code: '10', name: '每週1-2次' },
          { code: '15', name: '每週3-6次' },
          { code: '20', name: '每天' },
        ],
        BREATHE_HAS_AUX: [
          { code: '0', name: '無' },
          { code: '1', name: '有' },
        ],
        BREATHE_HAS_AUX_Y: [
          { code: '5', name: '氧氣機' },
          { code: '10', name: '呼吸器' },
          { code: '99', name: '其他' },
        ],
        BREATHE_HAS_AUX_Y_OX: [
          { code: '5', name: '鼻導管' },
          { code: '10', name: '氧氣面罩' },
        ],
        VACCINE_OPT: [
          { code: '5', name: '無' },
          { code: '10', name: '不知道' },
          { code: '15', name: '有' },
        ],
        VACCINE_TYPE: [
          { code: '5', name: '流感疫苗' },
          { code: '10', name: '肺炎雙球菌疫苗' },
          { code: '15', name: 'COVID-19 疫苗' },
          { code: '99', name: '其他' },
        ],
      },
      rules: {
        required: (value) => !!value || '此欄位為必填項目',
        requiredIf: (condition) => (value) => !condition || !!value || '此欄位為必填項目',
        dateFormat: (value) => /\d{8}/.test(value) || '日期格式錯誤，請使用 yyyyMMdd',
      },
    }
  },
  methods: {
    clearForm() {
      this.form = {
        MAIN_ID: '',
        ASSESSMENT_DT: '',
        ASSESSMENT_USER: '任侁',
        C_OPEN_EYE: '',
        C_LANGUAGE: [],
        C_SPORT: '',
        V_OPT: '',
        V_OBS_Y_L: [],
        V_OBS_Y_R: [],
        V_IS_ACT: false,
        V_HAS_AUX: false,
        V_HAS_AUX_Y: [],
        V_HAS_AUX_Y_OTHER: '',
        H_OPT: '',
        H_OBS_Y_L: [],
        H_OBS_Y_R: [],
        H_IS_ACT: false,
        H_HAS_AUX: false,
        H_HAS_AUX_Y: [],
        H_HAS_AUX_Y_OTHER: '',
        COM_OPT: '',
        COM_IS_ACT: '',
        COM_TALK: '',
        COM_UNDERSTAND: '',
        O_LOOK: '',
        O_LOOK_ABNORMAL: [],
        O_LOOK_ABNORMAL_OTHER: '',
        O_HAS_EAT: '',
        O_HAS_EAT_Y: '',
        O_HAS_EAT_Y_OTHER: '',
        O_DENTURE: '',
        O_DENTURE_15: [],
        O_DENTURE_15_OTHER: '',
        O_HAS_TOOL: '',
        O_CLEAN_TOOL1: [],
        O_CLEAN_TOOL2: [],
        O_CLEAN_TOOL3: [],
        O_SITUATION: '',
        S_ABDOMEN: '',
        S_ABDOMEN_OTHER: '',
        S_SQUIRM: '',
        S_SQUIRM_OTHER: '',
        S_DIGESTION: '',
        S_DIGESTION_OTHER: '',
        ED_FORM: '',
        ED_FORM_OTHER: '',
        ED_COLOR: '',
        ED_COLOR_OTHER: '',
        ED_AUX: '',
        ED_AUX_Y: '',
        ED_AUX_Y_OTHER: '',
        EU_FORM: '',
        EU_FORM_25: '',
        EU_FORM_25_OTHER: '',
        EU_COLOR: '',
        EU_COLOR_OTHER: '',
        EU_AUX: '',
        EU_AUX_Y: '',
        EU_AUX_Y_OTHER: '',
        SKIN_WARM: '',
        SKIN_WARM_OTHER: '',
        SKIN_WET: '',
        SKIN_WET_OTHER: '',
        SKIN_COLOR: '',
        SKIN_COLOR_OTHER: '',
        SKIN_HAS_EDEMA: false,
        SKIN_HAS_EDEMA_SCORE: '',
        SKIN_HAS_EDEMA_LEVEL: '',
        SKIN_FULL: '',
        M_UPPER_LEFT: '',
        M_UPPER_RIGHT: '',
        M_BOTTOM_LEFT: '',
        M_BOTTOM_RIGHT: '',
        M_MOVE: '',
        M_HAS_AUX: '',
        M_HAS_AUX_Y: [],
        M_HAS_AUX_Y_OTHER: '',
        F_OPT: '',
        F_OPT_10_TIMES: '',
        F_HAS_HURT: '',
        F_HAS_HURT_Y: [],
        F_HAS_HURT_Y_OTHER: '',
        B_OPT: '',
        B_OPT_Y: [],
        B_OPT_Y_OTHER: '',
        SLEEP_OPT: '',
        SLEEP_OPT_Y: [],
        SLEEP_OPT_Y_OTHER: '',
        SLEEP_HAS_MED: false,
        SLEEP_MED_TYPE: [],
        SLEEP_FREQ: '',
        BREATHE_HAS_AUX: '',
        BREATHE_HAS_AUX_Y: '',
        BREATHE_HAS_AUX_Y_OX: [],
        BREATHE_HAS_AUX_Y_OX_NOSE: '',
        BREATHE_HAS_AUX_Y_OX_MASK: '',
        BREATHE_HAS_AUX_Y_OTHER: '',
        VACCINE_OPT: '',
        VACCINE_TYPE: [],
        VACCINE_OPT_Y_5_YEAR: '',
        VACCINE_OPT_Y_10_YEAR: '',
        VACCINE_OPT_Y_15_YEAR: '',
        VACCINE_OPT_Y_99_YEAR: '',
        VACCINE_OPT_Y_5_REM: false,
        VACCINE_OPT_Y_10_REM: false,
        VACCINE_OPT_Y_15_REM: false,
        VACCINE_OPT_Y_99_REM: false,
        VACCINE_OPT_OTHER: '',
      }
      this.$refs.form.resetValidation()
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          const response = await axios.post('/api/mohw_assessment/qc104', this.form)
          if (response.status === 200) {
            alert('資料成功上傳！')
            this.clearForm()
          } else {
            throw new Error('資料上傳失敗')
          }
        } catch (error) {
          console.log(`上傳失敗：${error.response?.data?.message || error.message}`)
        }
      }
    },
  },
}
</script>

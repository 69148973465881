<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['dataDis', 'dataFloor', 'dataStep', 'labelStep'],
  data() {
    return {}
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'line', snap: true },
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: this.labelStep,
        },
        yAxis: [
          {
            type: 'value',
            name: '步數',
            position: 'left',
          },
          {
            type: 'value',
            position: 'right',
            splitLine: {
              show: false,
            },
            min: 0,
            max: 60,
            interval: 10,
          },
        ],
        series: [
          {
            name: '步數',
            yAxisIndex: 0,
            data: this.dataStep,
            color: '#539165',
            type: 'line',
            smooth: true,
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            name: '行走距離',
            yAxisIndex: 1,
            data: this.dataDis,
            color: '#3F497F',
            type: 'bar',
          },
          {
            name: '爬梯數',
            yAxisIndex: 1,
            data: this.dataFloor,
            color: '#F7C04A',
            type: 'bar',
          },
        ],
      }
      chart.setOption(option)
    },
  },
  watch: {
    dataDis() {
      this.renderChart()
    },
    dataFloor() {
      this.renderChart()
    },
    dataStep() {
      this.renderChart()
    },
    labelStep() {
      this.renderChart()
    },
  },
}
</script>
<style scoped>
.chart {
  width: 600px;
  height: 400px;
}

@media (max-width: 600px) {
  .chart {
    width: 100vw;
    height: 100vw;
  }
}
</style>

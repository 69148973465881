<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate"> 今日 </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn tile color="#BB2525" class="white--text" @click="prevDay" elevation="0">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn @click="menu = true" tile color="#BB2525" class="white--text" elevation="0">
              {{ formattedDate }}
            </v-btn>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn tile color="#BB2525" class="white--text" @click="nextDay" elevation="0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          >
        </v-row>
      </div>
      <div class="mx-2">
        <v-btn tile color="#813d67" class="white--text" elevation="0" @click="checkUsage">
          總使用量
        </v-btn>
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :search="filter_text"
        no-results-text="無搜尋的資料"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-text-field v-model="filter_text" label="搜尋" class="mx-4"></v-text-field>
        </template>
        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item, index }">
          <div :key="header.value">
            <span
              v-if="item[header.value] == 0"
              @click="enterUsage(item, index)"
              class="text-add hover-add"
              @mouseover="mouseoverEffect(header.value)"
              @mouseout="mouseoutEffect(header.value)"
            >
              ✚新增
            </span>
            <span v-else @click="enterUsage(item, index)" class="hover-effect">
              {{ item[header.value] }}
            </span>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          {{ caseName }} 的衛材清單
          <v-spacer />
          <v-btn v-if="exist" color="#614BC3" class="white--text" @click="enterVitalSign(index)">
            ✚新增資料
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col v-for="(value, key) in materials" :key="key" cols="4" sm="3">
                <v-select
                  dense
                  outlined
                  hide-details=""
                  :label="key"
                  v-model="materials[key]"
                  :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <!-- <v-btn text color="red" @click="dialog = false">拒測</v-btn> -->
            <v-btn v-if="exist" text color="blue" @click="dialog = false">確認</v-btn>
            <v-btn v-else text color="green" @click="submitUsage">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="usage_dialog" max-width="600">
      <v-card>
        <v-card-title class="justify-center font-weight-bold">衛材總使用量</v-card-title>
        <v-card-text>
          <v-data-iterator :items="check_usage" :items-per-page="-1" hide-default-footer>
            <template v-slot:item="{ item }">
              <v-list dense elevation="2">
                <v-list-item class="py-1 centered-content">
                  <v-list-item-content class="mx-2 justify-end">
                    {{ item.material_name }}
                  </v-list-item-content>
                  <v-list-item-content class="text-center">
                    : {{ item.quantity_used || '無' }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-data-iterator></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      headers: [{ text: '姓名', value: 'name' }],
      items: [],
      list_usage: [],
      check_usage: [],
      materials: '',
      dialog: false,
      usage_dialog: false,
      exist: false,
      staff_id: '',
      case_id: '',
      filter_text: '',
      index: '',
      caseName: '',
      time: '',
      m: '',
      l: '',
      diaper: '',
      wet_wipes: '',
      nursing_pad: '',
      highProtein: '',
      gloves: '',
      lotion: '',
      an_su: '',
      quickCoagulant: '',
      pantsM: '',
      pantsL: '',
      pantsXL: '',
      colorItems: {
        transportCheckIn: '#8d8d8d',
        check_in: '#8d8d8d',
        check_out: '#8d8d8d',
        m: '#8d8d8d',
        l: '#8d8d8d',
        diaper: '#8d8d8d',
        bloodOxygen: '#8d8d8d',
        wet_wipes: '#8d8d8d',
        highProtein: '#8d8d8d',
      },
      menu: false,
      selectedDate: new Date(),
    }
  },
  methods: {
    async fetchData() {
      let config = {
        method: 'get',
        url: `/api/warehouse/list_usage?start=${this.formattedDate}&end=${this.formattedDate}`,
        header: {},
      }

      let config_2 = {
        method: 'get',
        url: `/api/warehouse/check_usage?start=${this.formattedDate}&end=${this.formattedDate}`,
        header: {},
      }

      try {
        let res_list_usage = await axios.request(config)
        let res_check_usage = await axios.request(config_2)
        this.list_usage = res_list_usage.data.data
        this.check_usage = res_check_usage.data.data
        this.processData(this.list_usage)
      } catch (error) {
        console.log(error)
      }
    },
    checkUsage() {
      this.usage_dialog = true
    },
    async submitUsage() {
      const items = Object.keys(this.materials).map((key) => ({
        item: key,
        quantities: this.materials[key],
      }))
      this.dialog = false

      let data = JSON.stringify({
        case_num: this.case_id,
        items: items,
      })
      console.log(data)
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/warehouse/record_usage/',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }
      try {
        let res = await axios.request(config)
        console.log(res.data.status == 200)
        if (res.data.status == 200) {
          this.fetchData()
        }
      } catch (error) {
        console.log(error)
      }
    },
    processData(data) {
      const dynamicHeaders = new Set()
      data.forEach((person) => {
        person.items.forEach((item) => {
          dynamicHeaders.add(item.material_name)
        })
      })
      this.headers = [
        { text: '姓名', value: 'name', align: 'center', width: '90px' },
        ...Array.from(dynamicHeaders).map((h) => ({
          text: h,
          value: h,
          align: 'center',
          width: '90px',
        })),
      ]
      this.items = data.map((person) => {
        const itemRecord = {
          name: `${person.name} (${person.room})`,
          totalPrice: person.total_price,
        }
        person.items.forEach((item) => {
          itemRecord[item.material_name] = item.quantity_used
        })
        return itemRecord
      })
    },
    getCurrentTime() {
      this.time = new Date().toLocaleString()
    },
    getCurrentDate() {
      this.selectedDate = new Date()
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1)
      this.selectedDate = new Date(this.selectedDate)
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1)
      this.selectedDate = new Date(this.selectedDate)
    },
    enterUsage(item, index) {
      this.case_id = this.list_usage[index].case_num
      console.log(this.case_id)
      this.caseName = item.name
      let filteredInfo = JSON.parse(JSON.stringify(item))
      delete filteredInfo.name
      delete filteredInfo.totalPrice
      this.materials = filteredInfo
      this.dialog = true
    },
    mouseoverEffect(headerValue) {
      this.colorItems[headerValue] = '#258525'
    },
    mouseoutEffect(headerValue) {
      this.colorItems[headerValue] = '#8d8d8d'
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear()
        const month = this.selectedDate.getMonth() + 1
        const day = this.selectedDate.getDate()
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
      },
      set(newValue) {
        const [year, month, day] = newValue.split('-').map(Number)
        this.selectedDate = new Date(year, month - 1, day)
      },
    },
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    selectedDate() {
      this.fetchData()
    },
  },
  created() {
    let staff = sessionStorage.getItem('staff')
    this.staff_id = JSON.parse(staff).id
  },
}
</script>

<style>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.text-add {
  font-size: 14px;
  color: #8d8d8d;
}

.centered-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.v-data-table-header {
  min-width: 120px;
}
</style>
